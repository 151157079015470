import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    const [immuniwebSecureSeal,setImmuniwebSecureSeal]=useState('');
    useEffect(()=>{
        if ( ('localhost' == window.location.hostname) || ('app.perfectcheck.com' == window.location.hostname) || ('qa.perfectcheck.com' == window.location.hostname) || ('app.perfectcheck.com' == 'www.'+window.location.hostname) ) {
            var immuniweb_secure_seal = '<a target="_blank" href="https://portal.immuniweb.com/secure/seal/app.perfectcheck.com/31vU4B/seal.html" onclick="window.open(\'https://portal.immuniweb.com/secure/seal/app.perfectcheck.com/31vU4B/seal.html\', \'ImmuniWeb Assessed Website\', \'width=420,height=290\'); return false;"><img src="https://portal.immuniweb.com/secure/seal/app.perfectcheck.com/31vU4B/seal.png" width="150" height="auto"></a>';
            setImmuniwebSecureSeal(immuniweb_secure_seal);
        }
    },[])
    return (
        <React.Fragment>
            <footer className="footer" style={{height:"60px",paddingTop:"0",paddingBottom:"0"}}>
                <Container fluid>
                    <Row>
                        <Col sm={6} className={"align-self-center"}>
                            {new Date().getFullYear()} © { process.env.REACT_APP_NAME }.
                        </Col>
                        <Col sm={6} className={'text-end'}>
                            <div id="iw_seal" dangerouslySetInnerHTML={{__html: immuniwebSecureSeal}} />
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;