import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//VerifyCode Redux states
import { VERIFY_CODE } from "./actionTypes";
import { verifyCodeSuccessful, verifyCodeFailed } from "./actions";

//Include Both Helper File with needed methods
import {postDataAPI} from "../../../helpers/phpbackend_helper";

import {POST_VERIFY_CODE_DATA} from "../../../helpers/data_helper";

// Is user register successfull then direct plot user in redux.
function* verifyCode({ payload: { reg_ver_code } }) {
  try {
    const response = yield call(postDataAPI, {
      reg_ver_code: reg_ver_code,
      op:POST_VERIFY_CODE_DATA.op,
      serviceName:POST_VERIFY_CODE_DATA.serviceName
    });
    if (response.status === "ok") {
      yield put(verifyCodeSuccessful(response));
    } else {
      yield put(verifyCodeFailed(response));
    }
  } catch (error) {
    yield put(verifyCodeFailed(error));
  }
}

export function* watchVerifyCode() {
  yield takeEvery(VERIFY_CODE, verifyCode);
}

function* verifyCodeSaga() {
  yield all([fork(watchVerifyCode)]);
}

export default verifyCodeSaga;
