import {
  VERIFY_CODE,
  VERIFY_CODE_SUCCESSFUL,
  VERIFY_CODE_FAILED
} from "./actionTypes";

const initialState = {
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false
};

const VerifyCode = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_CODE:
      state = {
        ...state,
        loading: true,
        message: null,
      };
      break;
    case VERIFY_CODE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload.data,
        success: true,
        message: null,

      };
      break;
    case VERIFY_CODE_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        message: action.payload,
        error: true
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VerifyCode;
