export const USER_OUTLET_UPDATE = "USER_OUTLET_UPDATE"
export const USER_OUTLET_UPDATE_SUCCESS = "USER_OUTLET_UPDATE_SUCCESS"
export const USER_OUTLET_UPDATE_ERROR = "USER_OUTLET_UPDATE_ERROR"
export const USER_UPDATE_CURRENT_OUTLET = "USER_UPDATE_CURRENT_OUTLET"
export const GET_USER_INFO = "GET_USER_INFO"
export const USER_UPDATE_OUTLET_MENU = "USER_UPDATE_OUTLET_MENU"
export const USER_UPDATE_OUTLETS_LIST = "USER_UPDATE_OUTLETS_LIST"
export const USER_UPDATE_GROUPS_LIST = "USER_UPDATE_GROUPS_LIST"
export const USER_UPDATE_UNITS_LIST = "USER_UPDATE_UNITS_LIST"
export const USER_UPDATE_INFO = "USER_UPDATE_INFO"
