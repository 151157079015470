import React, { useState, useEffect } from "react";
import { Basic } from "../MenuEngineering/ScatterCharts";
import { currencyFormatWithoutDecimal, currencyFormatWithAlert } from "../../helpers/api_helper";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Table,
  Row,
  Col
} from "reactstrap";
import { POST_MENU_OPTIMIZATION, POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import { getDateByName, convertDateFormate } from "../../helpers/api_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { BasicModal } from "./ScatterChartsItemName";
import { Line } from "./LineColumnChart";
import CustomPagination from "../../Components/Common/CustomPagination";
import itemCategory from "../../assets/images/item-category.jpg";

const SellingItemPopup = ({ leftFilter, leftCustomDate, itemData, setshowItemModal, showItemModal, rightFilter, mealPeriod, defaultCourse, selectedPage, recalculate }) => {

  const itemsPerPage = 50;
  const [ServersTableArrayAjax, setServersTableArrayAjax] = useState(true);
  const [menuItemAjaxModal, setMenuItemAjaxModal] = useState(true);
  const [modalItemID, setModalItemID] = useState(false);
  const [ServersTableArray, setServersTableArray] = useState([]);
  const [Top_item_Correlation, setTop_item_Correlation] = useState([]);
  const [CorrelationTableMsg, setCorrelationTableMsg] = useState('');
  let [ItemNameArray, setItemNameArray] = useState([]);
  const [menuItemDataModal, setMenuItemDataModal] = useState({
    Courses: [],
    MenuItems: [],
  });
  const [item_revenue_chkcount, setitem_revenue_chkcount] = useState([]);
  const [showModelPopup, setShowModelPopup] = useState(false);
  const ShowModelPopupToggle = () => {
    setShowModelPopup(!showModelPopup);
  };
  const [pageCount_ServerTable, setPageCount_ServerTable] = useState(0);
  const [selectedPage_ServerTable, setselectedPage_ServerTable] = useState(0);

  useEffect(() => {
    console.log(itemData);
    setModalItemID(itemData.item_id);
    setshowItemModal(true);
    for (let i = ItemNameArray.length - 1; i >= 0; i--) {
      ItemNameArray.splice(i, 1);
    }
    ItemNameArray.push(itemData);

    /****** CALL Item Graph API ******/
    let leftFilterData = false;
    if (
      leftFilter.value !== "Custom" ||
      (leftFilter.value === "Custom" &&
        leftCustomDate[0] &&
        leftCustomDate[1])
    ) {
      leftFilterData =
        leftFilter.value === "Custom"
          ? {
            from: convertDateFormate(leftCustomDate[0]),
            to: convertDateFormate(leftCustomDate[1]),
          }
          : getDateByName(leftFilter.value);
    }
    if (leftFilterData) {
      postDataAPI({
        leftFilter: leftFilterData,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.item_graph_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage,
        recalculate: recalculate,
        rightFilter: rightFilter,
        item_id: itemData && itemData.item_id, // Check if itemData is defined
      }).then((response) => {
        setMenuItemAjaxModal(false);
        if (response.status === "ok") {
          setMenuItemDataModal(response.data);
        } else {
          console.log(response);
        }
      });
    } else {
      console.log(1);
    }
    /***** END *****/

    /****** CALL RIght Graph API ******/
    if (
      leftFilter.value !== "Custom" ||
      (leftFilter.value === "Custom" &&
        leftCustomDate[0] &&
        leftCustomDate[1])
    ) {
      leftFilterData =
        leftFilter.value === "Custom"
          ? {
            from: convertDateFormate(leftCustomDate[0]),
            to: convertDateFormate(leftCustomDate[1]),
          }
          : getDateByName(leftFilter.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_KPI_DASHBOARD.serviceName,
        op: POST_KPI_DASHBOARD.item_revenue_chkcount_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage,
        recalculate: recalculate,
        rightFilter: rightFilter,
        item_id: itemData.item_id,
      }).then((response) => {
        setMenuItemAjaxModal(false);
        if (response.status === "ok") {
          // console.log(response.data.data);
          setitem_revenue_chkcount(response.data.data);
        } else {
          console.log(response);
        }
      });
    } else {
      console.log(1);
    }
    /***** END *****/


    /****** Server Table API Call *******/
    if (
      leftFilter.value !== "Custom" ||
      (leftFilter.value === "Custom" &&
        leftCustomDate[0] &&
        leftCustomDate[1])
    ) {
      leftFilterData =
        leftFilter.value === "Custom"
          ? {
            from: convertDateFormate(leftCustomDate[0]),
            to: convertDateFormate(leftCustomDate[1]),
          }
          : getDateByName(leftFilter.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_KPI_DASHBOARD.serviceName,
        op: POST_KPI_DASHBOARD.item_server_qty_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage_ServerTable,
        recalculate: recalculate,
        rightFilter: rightFilter,
        item_id: itemData.item_id,
      }).then((response) => {
        // setMenuItemAjaxModal(false);
        setServersTableArrayAjax(false);
        if (response.status === "ok") {
          // console.log(response.data.data);
          setServersTableArray(response.data.data);
          setPageCount_ServerTable(Math.ceil(response.data.totalData / itemsPerPage));
        } else {
          console.log(response);
          setPageCount_ServerTable(0);
        }
      });
    } else {
      console.log(1);
    }
    /***** END *****/


    /***** Correlation Table API Call *****/
    if (
      leftFilter.value !== "Custom" ||
      (leftFilter.value === "Custom" &&
        leftCustomDate[0] &&
        leftCustomDate[1])
    ) {
      leftFilterData =
        leftFilter.value === "Custom"
          ? {
            from: convertDateFormate(leftCustomDate[0]),
            to: convertDateFormate(leftCustomDate[1]),
          }
          : getDateByName(leftFilter.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        rightFilter: rightFilter,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        serviceName: POST_KPI_DASHBOARD.serviceName,
        op: POST_KPI_DASHBOARD.item_correlation_op,
        page: selectedPage,
        recalculate: recalculate,
        item_id: itemData.item_id,
      }).then((response) => {
        setMenuItemAjaxModal(false);
        if (response.status === "ok") {
          // console.log("Top_item_Correlation",response.data.data);
          setTop_item_Correlation(response.data.data.item_data);
          setCorrelationTableMsg(response.data.data.itmCourseMsg);
        } else {
          console.log(response);
        }
      });
    } else {
      console.log(1);
    }
    /***** END *****/
  }, [
    leftFilter,
    leftCustomDate,
    leftFilter,
    rightFilter,
    mealPeriod,
    selectedPage,
    ItemNameArray,
    setshowItemModal,
    itemData,
    leftCustomDate,
    defaultCourse,
    recalculate,
    selectedPage_ServerTable
  ]);

  return (
    <>
      <Modal className="modal-dialog modal-fullscreen custom-bg" isOpen={showItemModal} toggle={() => {
        setshowItemModal(false);
      }} id="fullscreeexampleModal">
        <ModalHeader className="modal-title justify-content-end">
          {/* Title here */}
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setshowItemModal(false);
            }}
            aria-label="Close"
          ></button>
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody style={{ overflowY: 'auto' }}>

              {/* Single Item Table */}
              <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                <thead>
                  <tr style={{ textAlign: 'center', backgroundColor: '#f3f3f9' }}>
                    <th scope="col">Item </th>
                    <th scope="col">Course </th>
                    <th scope="col" className="text-start">Sold</th>
                    <th scope="col">Menu Price </th>
                    <th scope="col">Actualised Price </th>
                    <th scope="col">Item Cost </th>
                    <th scope="col">Item Gross <br /> Profit</th>
                    <th scope="col">Total Cost</th>
                    <th scope="col">Total Sales </th>
                    <th scope="col">Sales Mix %</th>
                    <th scope="col">Revenue Mix %</th>
                    <th scope="col">Contribution <br /> Margin</th>
                    <th scope="col">Profit <br /> Contribution</th>
                    <th scope="col">Popularity <br /> Contribution</th>
                    <th scope="col">Performance <br /> Group</th>
                  </tr>
                </thead>
                <tbody>
                  {(ItemNameArray || []).map((itemData, key) => (
                    <tr key={key} style={{ textAlign: 'center' }}>
                      <td>{itemData.name}</td>
                      <td>
                        {/* {itemData.course_name} */}
                        <span
                          onClick={ShowModelPopupToggle}
                          className={`badge bg-${itemData.course_name === "Starter"
                            ? "warning"
                            : itemData.course_name === "Drinks"
                              ? "danger"
                              : itemData.course_name === "Main"
                                ? "primary"
                                : itemData.course_name === "Desserts"
                                  ? "success"
                                  : itemData.course_name === "Others"
                                    ? "info"
                                    : "light text-dark"
                            }
                                        `}
                        >
                          {itemData.course_name}
                        </span>
                      </td>
                      <td>{itemData.sold}</td>
                      <td>{currencyFormatWithAlert(itemData.sell_price)}</td>
                      <td>{currencyFormatWithAlert(itemData.actualised_price)}</td>
                      <td>{currencyFormatWithAlert(itemData.item_cost)}</td>
                      <td>{currencyFormatWithAlert(itemData.item_gross_profit)}</td>
                      <td>{currencyFormatWithAlert(itemData.total_cost)}</td>
                      <td>{currencyFormatWithoutDecimal(itemData.total_sales)}</td>
                      <td>{itemData.sales_mix + "%"}</td>
                      <td>{itemData.revenueMix + "%"}</td>
                      <td>{currencyFormatWithAlert(itemData.ContributionMargin)}</td>
                      <td>
                        <span
                          className={`badge badge-soft-${itemData.profit_contribution === "Low"
                            ? "danger"
                            : "success"
                            }
                                  `}
                        >
                          {itemData.profit_contribution}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge badge-soft-${itemData.popularity_contribution === "Low"
                            ? "danger"
                            : "success"
                            }
                                  `}
                        >
                          {itemData.popularity_contribution}
                        </span>
                      </td>
                      <td>
                        <span
                          onClick={ShowModelPopupToggle}
                          className={`badge bg-${itemData.performance_group === "Plowhorse"
                            ? "warning"
                            : itemData.performance_group === "Dog"
                              ? "danger"
                              : itemData.performance_group === "Cash Cow"
                                ? "warning"
                                : itemData.performance_group === "Puzzle"
                                  ? "light text-dark"
                                  : "success"
                            }
                                  `}
                        >
                          {itemData.performance_group}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Two Charts */}
              <CardBody>
                <Row className="my-3">
                  <Col lg={6}>
                    <BasicModal
                      series={menuItemDataModal.MenuItems}
                      leftText="Contribution Margin"
                      dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info", "--vz-dark", "--vz-secondary", "--vz-warning"]'
                    />
                  </Col>
                  <Col lg={6}>
                    <Line
                      series={item_revenue_chkcount}
                      dataColors='["--vz-primary", "--vz-danger"]'
                    />
                  </Col>
                </Row>
              </CardBody>

              {/*  Title: Top itemData Correlation Table */}
              <h5>Top Item Correlation</h5>
              <h6> {CorrelationTableMsg !== '' ? '(' + CorrelationTableMsg + ')' : ''} </h6>
              {Top_item_Correlation.length > 0 &&
                <Table className="table-hover table-striped align-middle table-nowrap mb-5">
                  <thead>
                    <tr style={{ textAlign: 'center', backgroundColor: '#f3f3f9' }}>
                      <th scope="col">Name </th>
                      <th scope="col">Category / Tribe</th>
                      <th scope="col">% Chosen</th>
                      <th scope="col">Performance Group</th>
                      <th scope="col">Item Sold</th>
                      <th scope="col">Revenue</th>
                      <th scope="col">Average Check</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Top_item_Correlation.map((itemData, key) => (
                      <tr key={key} style={{ textAlign: 'center' }}>
                        <td>{itemData.item_name}</td>
                        <td>
                          <span
                            onClick={ShowModelPopupToggle}
                            className={`badge bg-${itemData.cfv_display_name === "Starter"
                              ? "warning"
                              : itemData.cfv_display_name === "Drinks"
                                ? "danger"
                                : itemData.cfv_display_name === "Main"
                                  ? "primary"
                                  : itemData.cfv_display_name === "Desserts"
                                    ? "success"
                                    : itemData.cfv_display_name === "Others"
                                      ? "info"
                                      : "light text-dark"
                              }
                                        `}
                          >
                            {itemData.cfv_display_name}
                          </span>
                        </td>
                        <td>{itemData.ref_count} %</td>
                        {/* <td>{itemData.cat_group !== '' ? itemData.cat_group : '-'}</td>                                                                        */}
                        <td>
                          <span
                            className={`badge bg-${itemData.cat_group === "Plowhorse"
                              ? "warning"
                              : itemData.cat_group === "Dog"
                                ? "danger"
                                : itemData.cat_group === "Cash Cow"
                                  ? "warning"
                                  : itemData.cat_group === "Puzzle"
                                    ? "light text-dark"
                                    : "success"
                              }
                                        `}
                          >{itemData.cat_group}</span>
                        </td>
                        <td>{itemData.item_sold}</td>
                        <td>{currencyFormatWithAlert(itemData.item_revenue)}</td>
                        <td>{currencyFormatWithAlert(itemData.avg_check)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              }

              {Top_item_Correlation.length === 0 &&
                <Table className="table-hover table-striped align-middle table-nowrap mb-5">
                  <thead>
                    <tr style={{ textAlign: 'center', backgroundColor: '#f3f3f9' }}>
                      <th scope="col">Name </th>
                      <th scope="col">Category / Tribe</th>
                      <th scope="col">% Chosen</th>
                      <th scope="col">Performance Group</th>
                      <th scope="col">Item Sold</th>
                      <th scope="col">Revenue</th>
                      <th scope="col">Average Check</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td colSpan={7}>No data</td>
                    </tr>
                  </tbody>
                </Table>
              }

              {/*  Title: Servers Table */}
              <h5>Servers</h5>
              {ServersTableArray.length > 0 &&
                <div className="m-3">
                  <div
                    className={
                      ServersTableArrayAjax
                        ? "opacity-0 table-responsive table-card"
                        : "table-responsive table-card"
                    }
                  >
                    <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
                      <thead className="text-muted table-light">
                        <tr>
                          <th scope="col">Name </th>
                          <th scope="col">Quantity sold as %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ServersTableArray.map((itemData, key) => (
                          <tr key={key} style={{ textAlign: 'center' }}>
                            <td>{itemData.server_name}</td>
                            <td>{itemData.qty_sold_perentage} %</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                  <div className="mt-4 float-end">
                    <nav>
                      <CustomPagination
                        pageCount={pageCount_ServerTable}
                        setSelectedPage={setselectedPage_ServerTable}
                      />
                    </nav>
                  </div>
                </div>
                // <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                //     <thead>
                //         <tr style={{textAlign: 'center', backgroundColor: '#f3f3f9'}}>
                //           <th scope="col">Name </th>
                //           <th scope="col">Quantity sold as %</th>                       
                //         </tr>
                //     </thead>
                //     <tbody>
                //         {ServersTableArray.map((itemData, key) => (
                //           <tr key={key} style={{textAlign: 'center'}}>
                //             <td>{itemData.server_name}</td>
                //             <td>{itemData.qty_sold_perentage} %</td>                                                                       
                //         </tr>
                //         ))}
                //     </tbody>
                // </Table>
              }

              {ServersTableArray.length === 0 &&
                <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                  <thead>
                    <tr style={{ textAlign: 'center', backgroundColor: '#f3f3f9' }}>
                      <th scope="col">Name </th>
                      <th scope="col">Quantity sold as %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td colSpan={2}>No data</td>
                    </tr>
                  </tbody>
                </Table>
              }

            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {/* Performance Group Modal only Image */}
      <Modal
        isOpen={showModelPopup}
        toggle={() => {
          ShowModelPopupToggle();
        }}
        centered
      >
        <ModalHeader className="justify-content-end">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              ShowModelPopupToggle();
            }}
            aria-label="Close"
          ></button>
        </ModalHeader>
        <ModalBody>
          <img src={itemCategory} className="img-fluid" alt="" />
        </ModalBody>
      </Modal>
    </>
  )
}
export default SellingItemPopup;