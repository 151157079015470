/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Container,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    Table,
    Button} from "reactstrap";
import Loader from "../../Components/Common/Loader"
import { ToastContainer, toast } from "react-toastify";
import {getDateByName, convertDateFormate} from "../../helpers/api_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
const UnallocatedChecksModal = ({getUnmappedChecks,unallocatedChecksModal,setUnallocatedChecksModal,checklist, activeDate,customDate}) => {

    const [mealPeriod, setMealPeriod] = useState(0);
    const [ajax,setAjax]=useState(false);
    const [filtermealperiod, setFiltermealperiod] = useState([]);
    const [selectedChecks, setSelectedChecks] = useState([]);
    const { filterData, filterAjax } = useSelector(
      (state) => ({
          filterData: state.Dashboard.filterData,
          filterAjax: state.Dashboard.filterAjax
      })
    );
    useEffect(() => {
      if (filterData.mealperiod) {
        setFiltermealperiod(filterData.mealperiod);
      }
    }, [filterAjax, filterData]);
    const handleMealPeriod = (event) => {
      setMealPeriod(event.target.value);
    }  
    
    const handleToastMessage = (type, message) => {
      // eslint-disable-next-line default-case
      switch (type) {
          case "error":
              toast.error(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" });
              break;
          case "success":
              toast.success(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })
              break;
      }
  } 
    const handleAllocate=async ()=>{
      if(mealPeriod==0){
        handleToastMessage('error', "Please select meal period");
      }else if(selectedChecks.length==0){
        handleToastMessage('error', "Please select checks");
      }else{
        setAjax(true);
        const reponse = await postDataAPI({
          dateFilter: activeDate.value==='Custom'?{from:convertDateFormate(customDate[0]),to:convertDateFormate(customDate[1])}:getDateByName(activeDate.value),
          chk_list:selectedChecks,
          serviceName: 'service_period_data',
          op: 'set_unmapped_sp_id',
          sp_id:mealPeriod
        });
        setAjax(false);
        if (reponse.status === "ok") {
          handleToastMessage('success', reponse.message);
          setUnallocatedChecksModal(false);
          getUnmappedChecks()
        }else{
          handleToastMessage('error', reponse?.message??"Something went wrong. Please try again.");
        }
      }
    }
    
    return (
        <Modal
            size="xl"
            isOpen={unallocatedChecksModal}
            toggle={() => {
              setUnallocatedChecksModal(false);
            }}
            backdrop={"static"}
            id="staticBackdrop"
            centered
        >
        <ModalHeader
          toggle={() => {
            setUnallocatedChecksModal(false);
          }}
          className="mb-2"
        >
          Unallocated Checks
        </ModalHeader>
        <ModalBody>
          <Container>
              {
                  ajax && <Loader />
              }
              <div className="table-responsive tableFixHead">
                <Table className="table-bordered align-middle table-nowrap mb-0">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Check ID</th>
                            <th scope="col">Date</th>
                            <th scope="col">Start Time</th>
                            <th scope="col">End Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {checklist.map((check)=>{
                          return <tr key={check.chk_id}>
                              <td>
                              <div className="form-check">
                                  <Input className="form-check-input" 
                                    type="checkbox" 
                                    value={check.chk_id}
                                    checked={selectedChecks.includes(check.chk_id)}
                                    onChange={(event)=>{
                                      if(selectedChecks.includes(check.chk_id)){
                                        setSelectedChecks(selectedChecks.filter((checkID)=>checkID!=check.chk_id))
                                      }else{
                                        setSelectedChecks([...selectedChecks,check.chk_id]);
                                      }
                                    }}
                                    />
                              </div>
                              </td>
                              <td>{check.chk_id}</td>
                              <td>{check.row_dt}</td>
                              <td>{check.start_time}</td>
                              <td>{check.end_time}</td>
                          </tr>
                        })}
                    </tbody>
                </Table>
              </div>
              <div className="mt-5 d-flex">
              <select
                  className="form-select"
                  value={mealPeriod}
                  id="mealPeriod"
                  onChange={handleMealPeriod}
                  style={{maxWidth:"250px",marginRight:"10px"}}
              >
                  <option value="0">Select Meal Period</option>
                  {!filterAjax &&
                      filtermealperiod.length > 0 &&
                      filtermealperiod.map((item) => {
                          return (
                              <option key={item.sp_id} value={item.sp_id}>
                                  {item.title +
                                      " (" +
                                      item.start_time +
                                      " To " +
                                      item.end_time +
                                      ")"}
                              </option>
                          );
                      })}
              </select>
              <Button color="primary" onClick={handleAllocate}>Allocate</Button>
              </div>
              <ToastContainer />
          </Container>
        </ModalBody>
      </Modal>
    )
}
export default UnallocatedChecksModal;