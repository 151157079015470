import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
import TableSeatOptimization from "../pages/TableSeatOptimization";
import MenuEngineering from "../pages/MenuEngineering";
import ServerPerformance from "../pages/ServerPerformance";
import MenuDesign from "../pages/MenuDesign";
import ServerUpselling from "../pages/ServerPerformance/ServerUpselling"

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import PasswordReset from '../pages/Authentication/PasswordReset';
import PasswordUpdate from '../pages/Authentication/PasswordUpdate';


// User Profile
import UserProfile from "../pages/Authentication/UserProfile";
import FlashDashboard from "../pages/DashboardEcommerce/FlashDashboard";
import NotificationConfig from "../pages/Authentication/NotificationConfig";
import ForecastBudget from "../pages/Authentication/ForecastBudget"
import Support from "../pages/Authentication/Support";

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardEcommerce, right_key:'kpi_dashboard' },
  { path: "/index", component: DashboardEcommerce, right_key:'kpi_dashboard' },
  { path: "/forecast", component: FlashDashboard, right_key:'forecast' },
  { path: "/table-seat-optimization", component: TableSeatOptimization, right_key:'table_seat_optimization' },
  { path: "/menu-optimization", component: MenuEngineering, right_key:'menu_optimization' },
  { path: "/menu-design", component: MenuDesign, right_key:'menu_design' },
  { path: "/server-performance", component: ServerPerformance, right_key:'server_performance' },
  { path: "/upselling", component: ServerUpselling, right_key:'upselling' },
  { path: "/profile", component: UserProfile, right_key:'profile' },
  { path: "/notification-config", component: NotificationConfig, right_key: 'notification-config' },
  { path: "/forecast-and-budget", component: ForecastBudget, right_key: 'forecast-and-budget' },
  { path: "/support", component: Support, right_key:'support' },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },
  { path: "/password-reset/:verifycode", component: PasswordReset },
  { path: "/password-update/:verifycode", component: PasswordUpdate },
];

export { authProtectedRoutes, publicRoutes };