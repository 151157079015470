import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//VerifyEmail Redux states
import { VERIFY_EMAIL } from "./actionTypes";
import { verifyEmailSuccessful, verifyEmailFailed } from "./actions";

//Include Both Helper File with needed methods
import {postDataAPI} from "../../../helpers/phpbackend_helper";

import {POST_VERIFY_EMAIL_DATA} from "../../../helpers/data_helper";

// Is user register successfull then direct plot user in redux.
function* verifyEmail({ payload: { reg_ver_code } }) {
  try {
    const response = yield call(postDataAPI, {
      reg_ver_code: reg_ver_code,
      op:POST_VERIFY_EMAIL_DATA.op,
      serviceName:POST_VERIFY_EMAIL_DATA.serviceName
    });
    if (response.status === "ok") {
      yield put(verifyEmailSuccessful(response));
    } else {
      yield put(verifyEmailFailed(response));
    }
  } catch (error) {
    yield put(verifyEmailFailed(error));
  }
}

export function* watchVerifyEmail() {
  yield takeEvery(VERIFY_EMAIL, verifyEmail);
}

function* verifyEmailSaga() {
  yield all([fork(watchVerifyEmail)]);
}

export default verifyEmailSaga;
