import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  Button
} from "reactstrap";
import CourseBadge from "../../Components/Common/CourseBadge"

const CourseItem = (props) => {
    const {design,itemDetail,course,handleUpdateOption,courseOptions,optionOneDisabled,optionTwoDisabled,optionThreeDisabled,fetchItemsDetail}=props;
    const [openCatInfo,setOpenCatInfo]=useState(false);
    const isDisabled=!Object.keys(courseOptions).includes(course.course_id);
    const itemList=course.items??[];
    let itemDetailData={option1:{},option2:{},option3:{}}
    if(itemDetail[course.course_id]){
        itemDetailData.option1=itemDetail[course.course_id].option1??{};
        itemDetailData.option2=itemDetail[course.course_id].option2??{};
        itemDetailData.option3=itemDetail[course.course_id].option3??{};
    }
    const courcePrice=[];
    const courceCost=[];
    const profitPrice=[];
    useEffect(()=>{
        setOpenCatInfo(false);
    },[design])
    Object.keys(itemDetailData).forEach((key)=>{
        if(itemDetailData[key].price && itemDetailData[key].price!=""){
            courcePrice.push(itemDetailData[key].price);
        }
        if(itemDetailData[key].cost && itemDetailData[key].cost!=""){
            courceCost.push(itemDetailData[key].cost);
        }
        if(itemDetailData[key].cost && itemDetailData[key].cost!=""){
            profitPrice.push(itemDetailData[key].profit_percentage);
        }
    })
    const courseData={
        priceMax:courcePrice.length>0?Math.max(...courcePrice):0,
        priceMin:courcePrice.length>0?Math.min(...courcePrice):0,
        costMax:courceCost.length>0?Math.max(...courceCost):0,
        costMin:courceCost.length>0?Math.min(...courceCost):0,
        profitMax:profitPrice.length>0?Math.max(...profitPrice):0,
        profitMin:profitPrice.length>0?Math.min(...profitPrice):0
    }
    const handleOpenCatInfo = () => {
        setOpenCatInfo(!openCatInfo);
    }
    
    return (
        <>
            <tr className={isDisabled?'table-primary text-muted':''}>
                <th className="col">
                    <Button disabled={isDisabled} onClick={handleOpenCatInfo} color="link" outline className="fs-3 p-0 me-1">
                        {openCatInfo?<i className="ri-checkbox-indeterminate-line"></i>:<i className="ri-add-box-line"></i>}
                    </Button>
                    {/* {course.course_name} */}
                    <CourseBadge course_name={course.course_name} />
                </th>
                <td className={optionOneDisabled?"table-primary":""}>
                    <select
                    className={(isDisabled || optionOneDisabled)?"form-select text-muted":"form-select"}
                    style={{width:"150px", margin: 'auto'}}
                    disabled={isDisabled || optionOneDisabled}
                    value={courseOptions[course.course_id]?.option1??""}
                    onChange={(event)=>{handleUpdateOption(course.course_id,'option1',event.target.value)}}
                    >
                    <option value="0">Select Item</option>
                    {
                        itemList.map((item) => {
                            return (
                                <option disabled={item.item_status=="Inactive"} key={item.item_id} value={item.item_id}>
                                {item.item_name}
                                </option>
                            );
                        })
                    }
                    </select>
                </td>
                <td className={optionTwoDisabled?"table-primary":""}>
                    <select
                        className={(isDisabled || optionTwoDisabled)?"form-select text-muted":"form-select"}
                        style={{width:"150px", margin: 'auto'}}
                        disabled={isDisabled || optionTwoDisabled}
                        value={courseOptions[course.course_id]?.option2??""}
                        onChange={(event)=>{handleUpdateOption(course.course_id,'option2',event.target.value)}}
                        >
                        <option value="0">Select Item</option>
                        {
                            itemList.map((item) => {
                                return (
                                    <option disabled={item.item_status=="Inactive"} key={item.item_id} value={item.item_id}>
                                    {item.item_name}
                                    </option>
                                );
                            })
                        }
                        </select>
                </td>
                <td className={optionThreeDisabled?"table-primary":""}>
                    <select
                    className={(isDisabled || optionThreeDisabled)?"form-select text-muted":"form-select"}
                    style={{width:"150px", margin: 'auto'}}
                    disabled={isDisabled || optionThreeDisabled}
                    value={courseOptions[course.course_id]?.option3??""}
                    onChange={(event)=>{handleUpdateOption(course.course_id,'option3',event.target.value)}}
                    >
                        <option value="0">Select Item</option>
                        {
                            itemList.map((item) => {
                                return (
                                    <option disabled={item.item_status=="Inactive"} key={item.item_id} value={item.item_id}>
                                    {item.item_name}
                                    </option>
                                );
                            })
                        }
                    </select>
                </td>
                <td></td>
            </tr>
            { openCatInfo && <>
                <tr>
                    <td className="fw-medium">Category</td>
                    <td className={optionOneDisabled?"table-primary":""}>
                        {/* {itemDetailData.option1?.performance_group} */}
                        <span style={{fontSize: '12px'}}
                          className={`badge bg-${
                            itemDetailData.option1?.performance_group === "Plowhorse"
                              ? "warning"
                              : itemDetailData.option1?.performance_group === "Dog"
                              ? "danger"
                              : itemDetailData.option1?.performance_group === "Cash Cow"
                              ? "warning"
                              : itemDetailData.option1?.performance_group === "Puzzle"
                              ? "light text-dark"
                              : "success"
                          }
                          `}
                        >
                          {itemDetailData.option1?.performance_group}
                        </span>
                    </td>
                    <td className={optionTwoDisabled?"table-primary":""}>
                        {/* {itemDetailData.option2?.performance_group} */}
                        <span style={{fontSize: '12px'}}
                          className={`badge bg-${
                            itemDetailData.option2?.performance_group === "Plowhorse"
                              ? "warning"
                              : itemDetailData.option2?.performance_group === "Dog"
                              ? "danger"
                              : itemDetailData.option2?.performance_group === "Cash Cow"
                              ? "warning"
                              : itemDetailData.option2?.performance_group === "Puzzle"
                              ? "light text-dark"
                              : "success"
                          }
                          `}
                        >
                          {itemDetailData.option2?.performance_group}
                        </span>
                    </td>
                    <td className={optionThreeDisabled?"table-primary":""}>
                        {/* {itemDetailData.option3?.performance_group} */}
                        <span style={{fontSize: '12px'}}
                          className={`badge bg-${
                            itemDetailData.option3?.performance_group === "Plowhorse"
                              ? "warning"
                              : itemDetailData.option3?.performance_group === "Dog"
                              ? "danger"
                              : itemDetailData.option3?.performance_group === "Cash Cow"
                              ? "warning"
                              : itemDetailData.option3?.performance_group === "Puzzle"
                              ? "light text-dark"
                              : "success"
                          }
                          `}
                        >
                          {itemDetailData.option3?.performance_group}
                        </span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td className="fw-medium">Price</td>
                    <td className={optionOneDisabled?"table-primary":""}>{itemDetailData.option1?.price}</td>
                    <td className={optionTwoDisabled?"table-primary":""}>{itemDetailData.option2?.price}</td>
                    <td className={optionThreeDisabled?"table-primary":""}>{itemDetailData.option3?.price}</td>
                    <td>{courseData.priceMin}-{courseData.priceMax}</td>
                </tr>
                <tr>
                    <td className="fw-medium">Cost</td>
                    <td className={optionOneDisabled?"table-primary":""}>{itemDetailData.option1?.cost}</td>
                    <td className={optionTwoDisabled?"table-primary":""}>{itemDetailData.option2?.cost}</td>
                    <td className={optionThreeDisabled?"table-primary":""}>{itemDetailData.option3?.cost}</td>
                    <td>{courseData.costMin}-{courseData.costMax}</td>
                </tr>
                <tr>
                    <td className="fw-medium">Profit%</td>
                    <td className={optionOneDisabled?"table-primary":""}>{itemDetailData.option1?.profit_percentage}</td>
                    <td className={optionTwoDisabled?"table-primary":""}>{itemDetailData.option2?.profit_percentage}</td>
                    <td className={optionThreeDisabled?"table-primary":""}>{itemDetailData.option3?.profit_percentage}</td>
                    <td>{courseData.profitMin}-{courseData.profitMax}</td>
                </tr> 
            </> }
        </>
    )
}
export default CourseItem;