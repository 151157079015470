const CourseBadge = (props) => {
  const {course_name, extraStyle}=props
  return (
    <span style={{fontSize: '12px', ...extraStyle}}
        className={`badge bg-${course_name === "Starter"
          ? "warning"
          : course_name === "Drinks"
            ? "danger"
            : course_name === "Main"
              ? "primary"
              : course_name === "Desserts"
                ? "success"
                : course_name === "Others"
                  ? "info"
                  : "light text-dark"
          }
                    `}
      >
        {course_name}
      </span>
    )
}
export default CourseBadge;
