import {
  GET_KIP_FILTER_API_DATA,
  KIP_FILTER_API_RESPONSE_SUCCESS,
  GET_KIP_WIDGET_API_DATA,
  KIP_WIDGET_API_RESPONSE_SUCCESS
} from "./actionType";

export const getKIPFilterData = (data) => ({
  type: GET_KIP_FILTER_API_DATA,
  payload: data
});

export const dashboardApiFilterSuccess = (actionType, data) => ({
  type: KIP_FILTER_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const getKIPWidgetData = (data) => ({
  type: GET_KIP_WIDGET_API_DATA,
  payload: data
});

export const dashboardApiWidgetSuccess = (actionType, data) => ({
  type: KIP_WIDGET_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});