import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import VerifyEmail from "./auth/verifyemail/reducer";
import UpdatePassword from "./auth/updatepassword/reducer";
import VerifyCode from "./auth/verifycode/reducer";
import ResetPassword from "./auth/resetpassword/reducer";
import UserOutletGroup from "./userOutletGroup/reducer";
// Dashboard
import Dashboard from "./dashboard/reducer";
//import MenuOptimization from "./menu-optimization/reducer";

const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    VerifyEmail,
    UpdatePassword,
    VerifyCode,
    ResetPassword,
    UserOutletGroup,
    Dashboard,
    //MenuOptimization
});

export default rootReducer;