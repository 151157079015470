import {
  USER_OUTLET_UPDATE,
  USER_OUTLET_UPDATE_SUCCESS,
  USER_OUTLET_UPDATE_ERROR,
  USER_UPDATE_CURRENT_OUTLET,
  GET_USER_INFO,
  USER_UPDATE_OUTLET_MENU,
  USER_UPDATE_OUTLETS_LIST,
  USER_UPDATE_GROUPS_LIST,
  USER_UPDATE_UNITS_LIST,
  USER_UPDATE_INFO
} from "./actionTypes";

export const userOutletUpdate = (data, history) => {
  return {
    type: USER_OUTLET_UPDATE,
    payload: { data, history },
  };
};

export const userOutletUpdateSuccess = message => {
  return {
    type: USER_OUTLET_UPDATE_SUCCESS,
    payload: message,
  };
};

export const userOutletUpdateError = message => {
  return {
    type: USER_OUTLET_UPDATE_ERROR,
    payload: message,
  };
};
export const userUpdateCurrentOutlet = data => {
  return {
    type: USER_UPDATE_CURRENT_OUTLET,
    payload: data,
  };
};

export const userUpdateOutletMenus = data => {
  return {
    type: USER_UPDATE_OUTLET_MENU,
    payload: data,
  };
};

export const userUpdateOutletsList = data => {
  return {
    type: USER_UPDATE_OUTLETS_LIST,
    payload: data,
  };
};

export const userUpdateGroupsList = data => {
  return {
    type: USER_UPDATE_GROUPS_LIST,
    payload: data,
  };
};

export const userUpdateUnitsList = data => {
  return {
    type: USER_UPDATE_UNITS_LIST,
    payload: data,
  };
};

export const userUpdateInfo = data => {
  return {
    type: USER_UPDATE_INFO,
    payload: data,
  };
};

export const getUserInfo = data => {
  return {
    type: GET_USER_INFO,
    payload: data,
  };
};