import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap"
import {
    GET_NOTIFICATION_LIST
  } from "../../helpers/data_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import Loader from "../../Components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
const notifyCustom = (msg, type) =>
  toast(msg, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
  });
const NotificationConfig = () => {
    const [selectedOutlets, setSelectedOutlets] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [notificationLoader,setNotificationLoader] = useState(false);

    const { userInfo, outlets, currentOutlet } = useSelector((state) => ({
        userInfo: state.UserOutletGroup.userInfo,
        outlets: state.UserOutletGroup.userOutlets,
        currentOutlet: state.UserOutletGroup.currentOutlet
      }));
    // console.log(outlets)
    const SingleOptions = Object.values(outlets).map((outlet) => ({
        value: outlet.outlet_id,
        label: outlet.outlet_name,
    }));
    const handleOutlet = (singleOption) => {
        console.log(SingleOptions,singleOption);
        setSelectedOutlets(singleOption);
    };
    useEffect(()=>{
        if(currentOutlet.typeID && selectedOutlets==false){
            const defaultOutlet=SingleOptions.find((op)=>op.value==currentOutlet.typeID);
            if(defaultOutlet){
                setSelectedOutlets(defaultOutlet);
            }else{
                setSelectedOutlets(SingleOptions[0]);
            }
        }
    },[currentOutlet])
    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: 260,
        }),
    };
    const handleUpdateOutlet = (event,nf_id) => {
        if(selectedOutlets?.value){
            setNotificationLoader(true);
            const {name,checked}=event.target;
            const nf_settings=notificationList.find((notification)=>notification.nf_id==nf_id)?.nf_settings;
            nf_settings.email[name].send=checked?'y':'n';
            postDataAPI({
                serviceName: GET_NOTIFICATION_LIST.serviceName,
                op: GET_NOTIFICATION_LIST.save_op,
                outletID:selectedOutlets?.value,
                user_id: userInfo && userInfo.user_id ? userInfo.user_id : "",
                nf_id:nf_id,
                nf_settings:JSON.stringify(nf_settings)
            }).then((response) => {
                setNotificationLoader(false);
                if (response.status === "ok") {
                    setNotificationList(notificationList.map((notification)=>{
                        if(notification.nf_id==nf_id){
                            notification.nf_settings=nf_settings;
                        }
                        return notification;
                    }))
                }
            });
        }else{
            notifyCustom("Please select outlet.", "danger");
        }
    }
    useEffect(()=>{
        setNotificationLoader(true);
        postDataAPI({
            serviceName: GET_NOTIFICATION_LIST.serviceName,
            op: GET_NOTIFICATION_LIST.op,
            outletID:selectedOutlets?.value,
            user_id: userInfo && userInfo.user_id ? userInfo.user_id : "",
        }).then((response) => {
            setNotificationLoader(false);
            if (response.status === "ok") {
            setNotificationList(response.data.map((notifiction)=>{
                if(notifiction.nf_settings){
                    notifiction.nf_settings=JSON.parse(notifiction.nf_settings);
                }else{
                    notifiction.nf_settings={
                        "email": {
                            "mon": {
                                "send": "n",
                                "time": ""
                            },
                            "tue": {
                                "send": "n",
                                "time": ""
                            },
                            "wed": {
                                "send": "n",
                                "time": ""
                            },
                            "thu": {
                                "send": "n",
                                "time": ""
                            },
                            "fri": {
                                "send": "n",
                                "time": ""
                            },
                            "sat": {
                                "send": "n",
                                "time": ""
                            },
                            "sun": {
                                "send": "n",
                                "time": ""
                            }
                        },
                        "notify": [],
                        "_notify": "Setting to send browser notifications !!",
                        "sms": [],
                        "_sms": "Setting to send sms for the specific report"
                    }
                }
                return notifiction;
            }));
            }
        });
    },[selectedOutlets])
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-sm-12 col-lg-4 d-flex mb-4 mt-3 mx-auto mx-md-auto">
                                        <div className="mx-2 mt-2 fw-bold">Outlet</div>
                                        <div>
                                            <Select
                                                value={selectedOutlets}
                                                isMulti={false}
                                                onChange={handleOutlet}
                                                options={SingleOptions}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    {notificationLoader && <Loader />}
                                    <table className={`table  table-bordered table-nowrap repeater ${notificationLoader ? "opacity-50" : ""}`}>
                                        <thead>
                                            <tr>
                                               
                                                <th scope="col" style={{ width: 250 }}>&nbsp;&nbsp; Reports&nbsp;&nbsp;</th>
                                                <th scope="col" style={{ width: 250 }}> Time</th>
                                                <th scope="col">Mon</th>
                                                <th scope="col">Tue</th>
                                                <th scope="col">Wed</th>
                                                <th scope="col">Thu</th>
                                                <th scope="col">Fri</th>
                                                <th scope="col">Sat</th>
                                                <th scope="col">Sun</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notificationList.map((notification, index)=>{                                                 
                                                return <tr key={index}>
                                                    <td>{notification.title}</td>
                                                    <td>{notification.nf_time} {notification.time_zone}</td>
                                                    <td>
                                                        <input type="checkbox" name="mon" className="form-check-input mon" value="Yes" onChange={(event)=>handleUpdateOutlet(event,notification.nf_id)} checked={notification?.nf_settings?.email?.mon?.send=='y'?true:false} />
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" name="tue" className="form-check-input tue" value="Yes" onChange={(event)=>handleUpdateOutlet(event,notification.nf_id)} checked={notification?.nf_settings?.email?.tue.send=='y'?true:false}/>
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" name="wed" className="form-check-input wed" value="Yes" onChange={(event)=>handleUpdateOutlet(event,notification.nf_id)} checked={notification?.nf_settings?.email?.wed.send=='y'?true:false}/>
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" name="thu" className="form-check-input thu" value="Yes" onChange={(event)=>handleUpdateOutlet(event,notification.nf_id)} checked={notification?.nf_settings?.email?.thu?.send=='y'?true:false}/>
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" name="fri" className="form-check-input fri" value="Yes" onChange={(event)=>handleUpdateOutlet(event,notification.nf_id)} checked={notification?.nf_settings?.email?.fri?.send=='y'?true:false}/>
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" name="sat" className="form-check-input sat" value="Yes" onChange={(event)=>handleUpdateOutlet(event,notification.nf_id)} checked={notification?.nf_settings?.email?.sat?.send=='y'?true:false}/>
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" name="sun" className="form-check-input sun" value="Yes" onChange={(event)=>handleUpdateOutlet(event,notification.nf_id)} checked={notification?.nf_settings?.email?.sun?.send=='y'?true:false}/>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>                                    
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default NotificationConfig