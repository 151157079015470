import {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESSFUL,
  UPDATE_PASSWORD_FAILED
} from "./actionTypes"

export const updatePassword = (data, history) => {
  return {
    type: UPDATE_PASSWORD,
    payload: { data, history},
  }
}

export const updatePasswordSuccessful = data => {
  return {
    type: UPDATE_PASSWORD_SUCCESSFUL,
    payload: data,
  }
}

export const updatePasswordFailed = data => {
  return {
    type: UPDATE_PASSWORD_FAILED,
    payload: data,
  }
}