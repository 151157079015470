import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//UpdatePassword Redux states
import { UPDATE_PASSWORD } from "./actionTypes";
import { updatePasswordSuccessful, updatePasswordFailed } from "./actions";

//Include Both Helper File with needed methods
import {postDataAPI} from "../../../helpers/phpbackend_helper";

import {POST_UPDATE_PASSWORD_DATA} from "../../../helpers/data_helper";
import {saveLoggedinUser} from "../../../helpers/api_helper";
let md5 = require('md5');

// Is user register successfull then direct plot user in redux.
function* updatePassword({ payload: { data, history } }) {
  try {
    const response = yield call(postDataAPI, {
      pwd: md5(data.old_password),
      npwd: md5(data.password),
      cpwd: md5(data.confrim_password),
      email: data.email,
      op:POST_UPDATE_PASSWORD_DATA.op,
      serviceName:POST_UPDATE_PASSWORD_DATA.serviceName
    });
    
    if (response.status === "ok") {
      saveLoggedinUser(JSON.stringify(response.data));
      yield put(updatePasswordSuccessful(response));
      history.push("/dashboard");  
    } else {
      yield put(updatePasswordFailed(response));
    }
  } catch (error) {
    yield put(updatePasswordFailed(error));
  }
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
}

function* updatePasswordSaga() {
  yield all([fork(watchUpdatePassword)]);
}

export default updatePasswordSaga;
