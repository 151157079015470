import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";


import {postDataAPI} from "../../../helpers/phpbackend_helper";

import {POST_FORGOT_PASSWORD_DATA} from "../../../helpers/data_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_API_URL) {
      const response = yield call(postDataAPI, {
        email: user.email,
        op:POST_FORGOT_PASSWORD_DATA.op,
        serviceName:POST_FORGOT_PASSWORD_DATA.serviceName
      });
      if (response.status==='ok') {
        yield put(userForgetPasswordSuccess(response.message));
      }else{
        yield put(userForgetPasswordError(response.message));
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
