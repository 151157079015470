import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import {coverColor} from "../../helpers/api_helper"
function findMaxValue(objects, property) {
    if (objects.length === 0) {
        return undefined; // Handle the case when the array is empty
    }

    return objects.reduce((max, obj) => {
        const value = obj[property];
        return value > max ? value : max;
    }, objects[0][property]);
}
  
const BasicModal = ({series,leftText,bottomText,maxXaxis,dataColors}) => {

    let maxValue;
    for(let i = 0; i < series.length; i++){
        maxValue = parseInt(series[i]['data'][0][0]);
    }

    var chartScatterBasicColors = getChartColorsArray(dataColors);     

    const options = {
        chart: {
            zoom: {
                enabled: true,
                type: "xy",
            },
            toolbar: {
                show: true,
            },
        },
        xaxis: {
            tickAmount: 3,
            min: 0,
            max:maxValue+50/5,
            title: {
                text: bottomText??"Quantity Sold",                
                style:{
                    colors:['#000000']
                }
            },            
        },
        yaxis: {
            title: {
                text: leftText,
                style: {
                    fontWeight: 600,
                },
            },
            tickAmount: 8
        },
        colors: chartScatterBasicColors,
        legend:{
            height:80,
            showForSingleSeries: true,
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="scatter"
                height={430}
            />
        </React.Fragment>
    );
};

export {
    BasicModal,
};
