import React from "react";
import ReactPaginate from "react-paginate";

function CustomPagination({ pageCount, setSelectedPage }) {
  const handlePageClick = (event) => {    
    // const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    // setItemOffset(newOffset);    
  };
  return (
    <React.Fragment>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={(event) => setSelectedPage(event.selected)}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        breakClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
    </React.Fragment>
  );
}

export default CustomPagination;
