import {
  USER_OUTLET_UPDATE,
  USER_OUTLET_UPDATE_SUCCESS,
  USER_OUTLET_UPDATE_ERROR,
  USER_UPDATE_CURRENT_OUTLET,
  USER_UPDATE_OUTLET_MENU,
  USER_UPDATE_OUTLETS_LIST,
  USER_UPDATE_GROUPS_LIST,
  USER_UPDATE_UNITS_LIST,
  USER_UPDATE_INFO,
} from "./actionTypes";

const initialState = {
  outletUpdateSuccessMsg: null,
  outletUpdateError: null,
  currentOutlet: null,
  userMenu: null,
  userOutlets: [],
  userGroups: [],
  userUnits: [],
  userInfo: {},
  loading: false,
};

const UserOutletGroup = (state = initialState, action) => {
  switch (action.type) {
    case USER_OUTLET_UPDATE:
      state = {
        ...state,
        outletUpdateSuccessMsg: null,
        outletUpdateError: null,
        loading: true,
      };
      break;
    case USER_OUTLET_UPDATE_SUCCESS:
      state = {
        ...state,
        outletUpdateSuccessMsg: action.payload,
        //loading: false,
      };
      break;
    case USER_OUTLET_UPDATE_ERROR:
      state = { ...state, outletUpdateError: action.payload, loading: false };
      break;
    case USER_UPDATE_CURRENT_OUTLET:
      state = { ...state, currentOutlet: action.payload };
      break;
    case USER_UPDATE_OUTLET_MENU:
      state = { ...state, userMenu: action.payload };
      break;
    case USER_UPDATE_OUTLETS_LIST:
      state = { ...state, userOutlets: action.payload, loading: false };
      break;
    case USER_UPDATE_GROUPS_LIST:
      state = { ...state, userGroups: action.payload };
      break;
    case USER_UPDATE_UNITS_LIST:
      state = { ...state, userUnits: action.payload };
      break;
    case USER_UPDATE_INFO:
      state = { ...state, userInfo: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserOutletGroup;
