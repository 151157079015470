import {
  GET_KIP_WIDGET_API_DATA,
  KIP_FILTER_API_RESPONSE_SUCCESS,
  KIP_WIDGET_API_RESPONSE_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  filterData: [],
  filterAjax:true,
  widgetData: [],
  widgetAjax:true,
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case KIP_FILTER_API_RESPONSE_SUCCESS:
      return {
        ...state,
        filterData: action.payload.data,
        filterAjax:false,
      };
    case GET_KIP_WIDGET_API_DATA:
      return {
        ...state,
        widgetAjax:true,
      };
    case KIP_WIDGET_API_RESPONSE_SUCCESS:
      return {
        ...state,
        widgetData: action.payload.data,
        widgetAjax:false,
      };
    default:
      return state;
  }
};
export default Dashboard;