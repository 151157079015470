import React from 'react';
import ReactApexChart from "react-apexcharts";


const TableMixByPartySize= ({ data, dataColors, allHaveCoverZero }) => {
    // eslint-disable-next-line no-debugger
    // debugger;
    // var chartColumnStackedColors = getChartColorsArray(dataColors);     
    //console.log("allHaveCoverZero",allHaveCoverZero)  
    const options = {      
        chart: {
            stacked: !0,
            toolbar: {
                show: true,
            },
            zoom: {
                enabled: !0,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: !1,
                borderRadius: 10,
            },
        },
        xaxis: {
            type: "category",
            categories: data.categories,
        },
        yaxis:{
            title:{
                text: 'Check Count'
            },            
        },
        legend: {
            show: allHaveCoverZero === false ? true : false
        },
        fill: {
            opacity: 1,
        },
        colors: dataColors,  
        tooltip:{
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                let series_html = '';
                
                //console.log(seriesIndex, dataPointIndex);
                series.forEach((s,key) => {
                  //currentTotal += s[dataPointIndex]   
                  series_html+='<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: '+(key+1)+'; display: flex">'+
                                '<span class="apexcharts-tooltip-marker" style="background-color: '+dataColors[key]+'"></span>'+
                                    '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">'+
                                        '<div class="apexcharts-tooltip-y-group">'+
                                            '<span class="apexcharts-tooltip-text-y-label">'+data.series[key].name+': </span>'+
                                            '<span class="apexcharts-tooltip-text-y-value">'+s[dataPointIndex]+'</span>'+
                                        '</div>'+
                                    '</div>'+
                            '</div>'       
                });
                return '<div class="custom-tooltip">' +
                  '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">'+
                   data.categories[dataPointIndex] +
                 '</div>'+
                 series_html +
                '</div>'
            }
        }            
    };
    console.log(data.series);
    return <ReactApexChart series={data.series} options={options} type="bar" height={350} />;
};
const TableCoverLineChar=({data,dataColors,allHaveCoverZeroAvg}) => {
    const options={
        chart: {
            toolbar: {
                show: true,
            },
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        yaxis:{
            title:{
                text: 'Average Check'
            },            
        },
        xaxis: {
          categories: data.categories,
        },
        legend: {
            show: allHaveCoverZeroAvg === false ? true : false
        },
        colors:dataColors
    };
    return <ReactApexChart options={options} series={data.series} type="line" height={350} />
}
export {TableMixByPartySize, TableCoverLineChar};