import { toast } from "react-toastify";

const notifyCustom = (msg, type) =>
  toast(msg, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
  });

export { notifyCustom };
