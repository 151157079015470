import React, { useState, useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import '../../custom.css'

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import UiContent from "../../Components/Common/UiContent";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import avatar from "../../assets/images/users/user-dummy-img.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import classnames from "classnames";
import {
  GET_NOTIFICATION_LIST,
  POST_SUBSCRIBE_NOTIFICATION,
} from "../../helpers/data_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Common/Loader";

const notifyCustom = (msg, type) =>
  toast(msg, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
  });
const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg'] };

function isValidFileType(fileName, fileType) {
  if(!fileName){
    return true;
  }
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}
const UserProfile = () => {
  const dispatch = useDispatch();

  const [email, setemail] = useState("admin@gmail.com");
  const [idx, setidx] = useState("1");
  const [tab, setTab] = useState("1");

  const [userName, setUserName] = useState("Admin");
  const [notificationListLoading, setNotificationListLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationListRefresh, setNotificationListRefresh] = useState(false);
  const [updateRefresh, setUpdateRefresh] = useState(false);
  const profileUploadRef = React.useRef();
  const { user, success, error } = useSelector((state) => ({
    user: state.Profile.user,
    success: state.Profile.success,
    error: state.Profile.error,
  }));

  const { userInfo } = useSelector((state) => ({
    userInfo: state.UserOutletGroup.userInfo,
  }));
// console.log(userInfo);
  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));

      if (!isEmpty(user)) {
        obj.first_name = user.first_name;
        sessionStorage.removeItem("authUser");
        sessionStorage.setItem("authUser", JSON.stringify(obj));
      }

      setUserName(obj.first_name);
      setemail(obj.email);
      setidx(obj._id || "1");

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, user]);

  useEffect(() => {
    setNotificationListLoading(true);
    postDataAPI({
      serviceName: GET_NOTIFICATION_LIST.serviceName,
      op: GET_NOTIFICATION_LIST.op,
      user_id: userInfo && userInfo.user_id ? userInfo.user_id : "",
    }).then((response) => {
      setNotificationListLoading(false);
      if (response.status === "ok") {
        setNotificationList(response.data);
      } else {
        console.log(response);
      }
    });
  }, [notificationListRefresh]);

  const [preview, setPreview] = useState(avatar);
  useEffect(() => {
    if(userInfo.profile_image){
      setPreview(userInfo.profile_image)
    }
  },[userInfo.profile_image])
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    validation.setFieldValue("profile_image", file); 
  };
  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreview(reader.result);
    };
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: userInfo && userInfo.first_name ? userInfo.first_name : "",
      last_name: userInfo && userInfo.last_name ? userInfo.last_name : "",
      establishment:
        userInfo && userInfo.establishment ? userInfo.establishment : "",
      city: userInfo && userInfo.city ? userInfo.city : "",
      id: userInfo && userInfo.user_id ? userInfo.user_id : "",
      //profile_image: userInfo && userInfo.profile_image ? userInfo.profile_image : null
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      establishment: Yup.string().required("Please Enter Your Establishment"),
      city: Yup.string().required("Please Enter Your City"),
      profile_image:Yup.mixed().test("is-valid-type", "Uploaded file is not a valid image. Only JPG, JPEG, PNG and GIF files are allowed.",
      value => isValidFileType(value && value.name.toLowerCase(), "image"))
      //profile_image:Yup.string().required("Please select profile image"),
    }),
    onSubmit: (values) => {
      //console.log(values,'values');
      dispatch(editProfile(values));
      // notifyCustom("Profile update successfully.", "success");
    },
  });

  const tabToggle = (selTabNo) => {
    if (tab !== selTabNo) {
      setTab(selTabNo);
    }
  };

  const onClickNotification = (item, checked) => {
    postDataAPI({
      serviceName: POST_SUBSCRIBE_NOTIFICATION.serviceName,
      op: POST_SUBSCRIBE_NOTIFICATION.op,
      nf_id: item.nf_id,
      user_id: userInfo && userInfo.user_id ? userInfo.user_id : "",
      action: checked ? "subscribe" : "unsubscribe",
    }).then((response) => {
      // setSellingItemAjax(false);
      if (response.status === "ok") {
        // setNotificationList(response.data);
        notifyCustom("Notification update successfully.", "success");
        setNotificationListRefresh(!notificationListRefresh);
      } else {
        console.log(response);
      }
    });
  };

  document.title = "Profile | " + process.env.REACT_APP_NAME;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs nav-justified mb-3">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: tab === "1" })}
                        onClick={() => {
                          tabToggle("1");
                        }}
                      >
                        Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: tab === "2" })}
                        onClick={() => {
                          tabToggle("2");
                        }}
                      >
                        Notification
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={tab}>
                    <TabPane tabId="1" id="base-justified-profile">
                      
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col lg="12">
                            {/* {error && error ? (
                              <Alert color="danger">{error}</Alert>
                            ) : null}
                            {success ? (
                              <Alert color="success">
                                Profile Update Successfully
                              </Alert>
                            ) : null} */}

                            <Card>
                              <CardBody>
                                <div className={
                                    validation.touched.profile_image &&
                                    validation.errors.profile_image
                                      ? "d-flex is-invalid"
                                      : "d-flex"
                                  }>
                                  <div className="mx-3" style={{position:"relative"}}>
                                        <div className="circle">
                                          <img className="profile-pic" src={preview} value={validation.values.profile_image || ""}/>
                                        </div>
                                        <div className="p-image">
                                            <i className="ri-camera-fill" onClick={(e)=>profileUploadRef.current.click()}></i>
                                            <input className="file-upload" ref={profileUploadRef} onChange={handleImageChange} type="file" accept="image/*"   />
                                        </div>
                                      </div>
                                    {/* <img
                                      src={avatar}
                                      alt=""
                                      className="avatar-md rounded-circle img-thumbnail"
                                    /> */}
                                  
                                  <div className="flex-grow-1 align-self-center">
                                    <div className="text-muted">
                                      <h5>
                                        {userInfo &&
                                          userInfo.first_name +
                                            " " +
                                            userInfo.last_name}
                                      </h5>
                                      <p className="mb-1">
                                        Email Id : {userInfo && userInfo.email}
                                      </p>
                                      {/* <p className="mb-0">Id No : #{idx}</p> */}
                                    </div>
                                  </div>
                                </div>
                                
                                {validation.touched.profile_image &&
                                  validation.errors.profile_image ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.profile_image}
                                    </FormFeedback>
                                  ) : null}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        <h4 className="card-title mb-4">Change User Name</h4>

                        <Card>
                          <CardBody>
                              <div className="form-group mb-3">
                                <Label className="form-label">
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="first_name"
                                  // value={name}
                                  className="form-control"
                                  placeholder="Enter First Name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.first_name || ""}
                                  invalid={
                                    validation.touched.first_name &&
                                    validation.errors.first_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.first_name &&
                                validation.errors.first_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.first_name}
                                  </FormFeedback>
                                ) : null}
                                <Input
                                  name="id"
                                  type="hidden"
                                  value={validation.values.id || ""}
                                />
                              </div>
                              <div className="form-group mb-3">
                                <Label className="form-label">
                                  Last Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="last_name"
                                  // value={name}
                                  className="form-control"
                                  placeholder="Enter Last Name"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.last_name || ""}
                                  invalid={
                                    validation.touched.last_name &&
                                    validation.errors.last_name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.last_name &&
                                validation.errors.last_name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.last_name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="form-group mb-3">
                                <Label
                                  htmlFor="establishment"
                                  className="form-label"
                                >
                                  Establishment{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="establishment"
                                  type="text"
                                  placeholder="Enter establishment"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.establishment || ""}
                                  invalid={
                                    validation.touched.establishment &&
                                    validation.errors.establishment
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.establishment &&
                                validation.errors.establishment ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation.errors.establishment}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="form-group mb-3">
                                <Label htmlFor="city" className="form-label">
                                  City <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="city"
                                  type="text"
                                  placeholder="Enter city"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.city || ""}
                                  invalid={
                                    validation.touched.city &&
                                    validation.errors.city
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.city &&
                                validation.errors.city ? (
                                  <FormFeedback type="invalid">
                                    <div>{validation.errors.city}</div>
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="text-left">
                                <Button type="submit" color="primary">
                                  Update
                                </Button>
                              </div>
                          </CardBody>
                        </Card>
                    </Form>
                    </TabPane>

                    <TabPane tabId="2" id="base-justified-notification">
                      {notificationListLoading && <Loader />}
                      <Card
                        className={notificationListLoading ? "opacity-0" : ""}
                      >
                        <CardHeader>
                          <h6 className="card-title mb-0">Notification</h6>
                        </CardHeader>
                        <CardBody>
                          <ul className="list-unstyled mb-0">
                            {(notificationList || []).map((item, index) => {
                              return (
                                <li className="d-flex pt-2 pb-2" key={index}>
                                  <div className="flex-grow-1">
                                    <label
                                      htmlFor="directMessage"
                                      className="form-check-label fs-14"
                                    >
                                      {item.title}
                                    </label>
                                    {/* <p className="text-muted">{item.nf_dev}</p> */}
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="form-check form-switch">
                                      <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        defaultChecked={
                                          item.action === "subscribe"
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          onClickNotification(
                                            item,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </CardBody>
                      </Card>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default UserProfile;
