import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const Line = ({series,leftText,bottomText,maxXaxis,dataColors}) => {

    let columnData = [];
    let lineData = [];
    let dateData = [];
    for(let i = 0; i < series.length; i++){
        columnData.push(Math.round(series[i]['revenue']));
        lineData.push(Math.round(series[i]['chk_count']));
        dateData.push(series[i]['row_dt']);
    }

    // console.log(dateData);

    const seriesvalues = [
        {
            name: "Revenue",
            type: "column",
            data: columnData,
        },
        {
            name: "Item Sold",
            type: "line",
            data: lineData,
        },
    ];

    var chartLineColumnColors = getChartColorsArray(dataColors);
    
    const options = {
        chart: {
            toolbar: {
                show: true,
            },
        },
        stroke: {
            width: [0, 4],
        },
        title: {
            // text: "Traffic Sources",
            style: {
                fontWeight: 600,
            },
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
        },
        labels: dateData,
        xaxis: {
            // type: "datetime",
        },
        yaxis: [
            {
                title: {
                    text: "Revenue Forecast (USS)",
                    style: {
                        fontWeight: 600,
                    },
                },
            },
            {
                opposite: true,
                title: {
                    text: "Item Sold",
                    style: {
                        fontWeight: 600,
                    },
                },
            },
        ],
        colors: chartLineColumnColors,              
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={seriesvalues}
                type="line"
                height={350}
            />
        </React.Fragment>
    );
};

export {
    Line
};
