import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

function generateTableOccupancyData(dataText,yrange) {
    var series = [];
    var dayName=['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
    var avg_y=0;
    for (let j = 0; j < dayName.length; j++) {
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        avg_y+=y;
        series.push({
            x: dayName[j],
            y: y
        });
    }
    series.push({
        x: dataText,
        y: Math.round((avg_y*7)/100)
    });
    return series;
}

const ColorRange = ({dataSeries, dataText,dataColors}) => {
    var chartHeatMapColors = getChartColorsArray(dataColors);
    const series= [{
            name: '11:00 PM-12:00 AM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '10:00 PM-11:00 PM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '09:00 PM-10:00 PM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '08:00 PM-09:00 PM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '07:00 PM-08:00 PM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '06:00 PM-07:00 PM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '05:00 PM-06:00 PM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '04:00 PM-05:00 PM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '02:00 AM-03:00 AM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '01:00 AM-02:00 AM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        },
        {
            name: '12:00 AM-01:00 AM',
            data: generateTableOccupancyData(dataText,{
                min: 0,
                max: 100
            })
        }
    ];
    var avgserie=[0,0,0,0,0,0,0,0];
    series.forEach(function(serie,index){
        serie.data.forEach(function(data,index){
            avgserie[index]=avgserie[index]+data.y;
        });
    });
    const serie=[];
    var dayName=['Mon','Tue','Wed','Thu','Fri','Sat','Sun',dataText];
    avgserie.forEach(function(avg,text){
        serie.push({
            x:dayName[text],
            y:Math.round((avg*7)/100)
        });
    });
    series.push({
        name:dataText,
        data:serie
    });
    var options = {        
        chart: {
            height: 450,
            type: 'heatmap',
            toolbar: {
                show: true
            }
        },
        stroke: {
            width: 0
        },
        plotOptions: {
            heatmap: {
                radius: 30,
                enableShades: false,              
                colorScale: {
                    ranges: [{
                            from: 0,
                            to: 0,
                            name: ' Very Low (0)',
                            color: '#d5d9dd'
                        },
                        {
                            from: 1,
                            to: 25,
                            name: 'Low (1-25)',
                            color: chartHeatMapColors[0],
                        },
                        {
                            from: 26,
                            to: 50,
                            name: 'Medium (26-50)',
                            color: chartHeatMapColors[1]
                        },
                        {
                            from: 51,
                            to: 75,
                            name: 'High (51-75)',
                            color: chartHeatMapColors[2]
                        },
                        {
                            from: 76,
                            to: 100,
                            name: 'Very High (76-100)',
                            color: chartHeatMapColors[3]
                        },
                    ],
                },


            }
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#fff']
            }
        },
        xaxis: {
            labels: {
                rotate: -45,
                rotateAlways: true,
            },
            type: 'category',
        },
        title: {
        text: '',
        style: {},
        },
    };

    return (
        <ReactApexChart
                series={dataSeries}
                options={options}
                type="heatmap"
                height={550}
            />
    )
}
export {
    ColorRange,
}

