import React, { useState, useEffect } from "react";

import { Basic } from "../MenuEngineering/ScatterCharts";
import {
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";
import { getDateByName, convertDateFormate } from "../../helpers/api_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";

const VisualModal = ({activeComparisonData,setShowVisualModal,showVisualModal,leftFilter,leftCustomDate,rightFilter,mealPeriod,defaultCourse,comparisonDate}) =>{

  const [menuItemData, setMenuItemData] = useState({
    Courses: [],
    MenuItems: [],
  });

  useEffect(() => {
    if (
      (leftFilter.value !== "Custom" ||
        (leftFilter.value === "Custom" &&
          leftCustomDate[0] &&
          leftCustomDate[1])) &&
      (rightFilter.value !== "Custom" ||
        (rightFilter.value === "Custom" &&
          comparisonDate[0] &&
          comparisonDate[1]))
    ) {
      let leftFilterData =
        leftFilter.value === "Custom"
          ? {
              from: convertDateFormate(leftCustomDate[0]),
              to: convertDateFormate(leftCustomDate[1]),
            }
          : getDateByName(leftFilter.value);
      let rightFilterData =
        activeComparisonData && activeComparisonData.datepiker
          ? {
              from: convertDateFormate(comparisonDate[0]),
              to: convertDateFormate(comparisonDate[1]),
            }
          : getDateByName(rightFilter.value);
          
        postDataAPI({
          leftFilter: leftFilterData,
          rightFilter: rightFilterData,
          mealPeriod: mealPeriod,
          course: defaultCourse,
          serviceName: POST_MENU_OPTIMIZATION.serviceName,
          op: POST_MENU_OPTIMIZATION.item_graph_op,
        }).then((response) => {
          //setMenuItemAjax(false);
          if (response.status === "ok") {
            setMenuItemData(response.data);
          } else {
            console.log(response);
          }
        });

    }
  }, [
    leftFilter,
    rightFilter,
    mealPeriod,
    comparisonDate,
    activeComparisonData,
    leftCustomDate,
    defaultCourse,
  ]);

  return (
    <Modal
      isOpen={showVisualModal}
      toggle={() => {
        setShowVisualModal(false);
      }}
      centered
      size="xl"
      backdrop={"static"}
    >
      <ModalHeader className="justify-content-end">
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            setShowVisualModal(false);
          }}
          aria-label="Close"
        ></button>
      </ModalHeader>
      <ModalBody>
        <div>
          <Basic
            series={menuItemData.MenuItems}
            leftText="Contribution Margin"
            dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info", "--vz-dark", "--vz-secondary", "--vz-warning"]'
          />
        </div>
      </ModalBody>
    </Modal>
    )
}
export default VisualModal;