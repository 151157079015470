/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import '../../custom.css'

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import UiContent from "../../Components/Common/UiContent";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import avatar from "../../assets/images/users/user-dummy-img.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import classnames from "classnames";
import {
  GET_NOTIFICATION_LIST,
  POST_SUBSCRIBE_NOTIFICATION,
} from "../../helpers/data_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components/Common/Loader";
import avatar1 from "../../assets/images/users/support.jpg";

const notifyCustom = (msg, type) =>
  toast(msg, {
    position: "top-right",
    hideProgressBar: true,
    className: `bg-${type} text-white`,
  });
const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg'] };

function isValidFileType(fileName, fileType) {
  if(!fileName){
    return true;
  }
  return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}
const Support = () => {
  
  useEffect(() => {
    
  }, []);

  const [preview, setPreview] = useState(avatar1);
   
  document.title = "Support | " + process.env.REACT_APP_NAME;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody className="text-center">
                  <img
                    style={{width: '50%',height:'50%'}}
                    src={preview}
                    alt="Support Image"
                  />
                  <p>Please send your queries and suggestions to <a href="mailto:support@perfectcheck.com">support@perfectcheck.com</a></p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Support;
