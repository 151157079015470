export const POST_LOGIN_DATA = { op: "checklogin", serviceName: "login_data" };
export const POST_REGISTER_DATA = {
  op: "register",
  serviceName: "register_data",
};
export const POST_VERIFY_EMAIL_DATA = {
  op: "verify-register-code",
  serviceName: "register_data",
};
export const POST_UPDATE_PASSWORD_DATA = {
  op: "change-password",
  serviceName: "login_data",
};
export const POST_FORGOT_PASSWORD_DATA = {
  op: "forget-password",
  serviceName: "login_data",
};
export const POST_VERIFY_CODE_DATA = {
  op: "verify-code",
  serviceName: "login_data",
};
export const POST_RESET_PASSWORD_DATA = {
  op: "reset-password",
  serviceName: "login_data",
};

export const POST_USER_OUTLET_UPDATE_DATA = {
  op: "userCurrentOutletSave",
  serviceName: "user_data",
};

export const GET_USER_INFO_DATA = {
  op: "getFrontUserData",
  serviceName: "common_data",
};


export const GET_NOTIFICATION_LIST = {
  op: "notification-list",
  serviceName: "notification_data",
  save_op : "notification_settings_save"
};

export const POST_SUBSCRIBE_NOTIFICATION = {
  op: "subscribe-notification",
  serviceName: "notification_data",
};

export const POST_UPDATE_USER_DETAIL = {
  op: "updateUserDetail",
  serviceName: "user_data",
};

export const POST_KPI_DASHBOARD = {
  serviceName: "kpi_data",
  filter_op: "kpi_filter",
  widget_op: "kpi_widget",
  forecast_op: "kpi_forecast",
  server_performance_op: "kpi_server_performance",
  forecast_heatmap_op: "kpi_forecast_heatmap",
  server_course_performance_op: "kpi_server_course_performance",
  server_performance_contribution_mix_op: "server_performance_contribution_mix",
  item_correlation_op: "item_correlation",
  item_server_qty_op: "item_server_qty",
  item_revenue_chkcount_op: "item_revenue_chkcount",
  server_top_bottom_performace_op: "server_top_bottom_performace",
  budget_forecast_list_op: "budget_forecast_list",
  save_budget_data_op: "save_budget_data",
  forecast_list_op: "forecast_list",
  save_forecast_data_op: "save_forecast_data",
  server_performance_contribution_mix:"server_performance_contribution_mix"
};

export const POST_MENU_OPTIMIZATION = {
  serviceName: "menu_data",
  selling_item_op: "selling_items",
  item_graph_op: "menu_item_graph",
  food_tribes_op: "menu_food_tribes",
  item_recommendation_op: "item_recommendation",
  item_recommendation_accept_op: "item_recommendation_accept",
  performance_analysis: "performance_analysis",
  performance_analysis_by_category: "performance_analysis_by_category"
};

export const POST_TABLE_SEAT_OPTIMIZATION = {
  serviceName: "table_seat_data",
  occupancy_heatmap_op: "occupancy_heatmap",
  table_revenue_op: "table_revenue",
  table_mixparty_op: "table_mixparty",
  table_cover_avgchk: "table_cover_avgchk",
  cover_sl_check_details: "cover_sl_check_details"
};

export const MENU_DESIGN = {
  serviceName: "menu_design_data",
  list_menu_design: "list_menu_design",
  fetch_course_items: "fetch_course_items",
  save_menu_design : "save_menu_design",
  fetch_md_detail : "fetch_md_detail",
  fetch_items_detail : "fetch_items_detail",
  update_menu_design_items : "update_menu_design_items",
  delete_design: "delete_menu_design"
};
