import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { USER_OUTLET_UPDATE, GET_USER_INFO } from "./actionTypes";
import {
  userOutletUpdateSuccess,
  userOutletUpdateError,
  userUpdateCurrentOutlet,
  userUpdateOutletMenus,
  userUpdateOutletsList,
  userUpdateGroupsList,
  userUpdateUnitsList,
  userUpdateInfo,
} from "./actions";

import { profileSuccess } from "../auth/profile/actions";

import { postDataAPI } from "../../helpers/phpbackend_helper";

import {
  POST_USER_OUTLET_UPDATE_DATA,
  GET_USER_INFO_DATA,
} from "../../helpers/data_helper";

import { getLoggedinUser, saveLoggedinUser, setLocalStorageData } from "../../helpers/api_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* userOutletUpdate({ payload: { data, history } }) {
  try {
    if (process.env.REACT_APP_API_URL) {
      const response = yield call(postDataAPI, {
        userID: data.userID,
        typeID: data.typeID,
        type: data.type,
        op: POST_USER_OUTLET_UPDATE_DATA.op,
        serviceName: POST_USER_OUTLET_UPDATE_DATA.serviceName,
      });
      if (response.status === "ok") {
        const obj = getLoggedinUser();
        obj.selectedOutletID=response.data.selectedOutletID;
        obj.selectedType=response.data.selectedType;
        saveLoggedinUser(JSON.stringify(obj));
        yield put(userOutletUpdateSuccess(response.data));
        yield put(profileSuccess(obj));
        //history.push("/dashboard");
        window.location.reload(false);
      } else {
        yield put(userOutletUpdateError(response.message));
      }
    }
  } catch (error) {
    yield put(userOutletUpdateError(error));
  }
}

//get login User Info
function* getUserInfo({ payload: user }) {
  try {
    if (process.env.REACT_APP_API_URL) {
      const response = yield call(postDataAPI, {
        userID: user.user_id,
        type: user.selectedType,
        typeID: user.selectedOutletID,
        op: GET_USER_INFO_DATA.op,
        serviceName: GET_USER_INFO_DATA.serviceName,
      });
      if (response.status === "ok") {
        console.log(response.data.userOutletDetails);
        setLocalStorageData('userOutletDetails',response.data.userOutletDetails)
        yield put(userUpdateCurrentOutlet(response.data.userOutletDetails));
        yield put(userUpdateOutletMenus(response.data.userMenus));
        yield put(userUpdateOutletsList(response.data.user_outlets));
        yield put(userUpdateGroupsList(response.data.user_groups));
        yield put(userUpdateUnitsList(response.data.user_units));
        yield put(userUpdateInfo(response.data.userInfo));
      }else{
        yield put(userUpdateInfo(user));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUserOutletUpdate() {
  yield takeEvery(USER_OUTLET_UPDATE, userOutletUpdate);
}

export function* watchGetUserInfo() {
  yield takeEvery(GET_USER_INFO, getUserInfo);
}

function* UserOutletSaga() {
  yield all([fork(watchUserOutletUpdate), fork(watchGetUserInfo)]);
}

export default UserOutletSaga;
