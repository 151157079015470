import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes";
import { profileSuccess, profileError } from "./actions";
import { POST_UPDATE_USER_DETAIL } from "../../../helpers/data_helper";
import { postDataAPI } from "../../../helpers/phpbackend_helper";
import { notifyCustom } from "../../../common/custom_function";

function* editProfile({ payload: { user } }) {

  try {    
    const response = yield call(postDataAPI, {
      user_id: user.id,
      fname: user.first_name,
      lname: user.last_name,
      establishment: user.establishment,
      city: user.city,
      profile_image:user.profile_image,
      op:POST_UPDATE_USER_DETAIL.op,
      serviceName:POST_UPDATE_USER_DETAIL.serviceName
    });
    if(response.status === "ok"){
      yield put(profileSuccess([]));    
      notifyCustom('Profile update successfully.', 'success')
    }
  } catch (error) {
    yield put(profileError(error));    
    
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
