import React, {useState} from "react";
import {
    Col,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
  } from "reactstrap";

import Flatpickr from "react-flatpickr";
import { displayDate } from "../../helpers/api_helper";

const dateTypes=[
    {
        "label": "Today",
        "value": "Today"
    },
    {
        "label": "Yesterday",
        "value": "Yesterday"
    },
    {
        "label": "This Week",
        "value": "ThisWeek"
    },
    {
        "label": "Past Two Weeks",
        "value": "PastTwoWeeks"
    },
    {
        "label": "This Month",
        "value": "ThisMonth"
    },
    {
        "label": "Last Month",
        "value": "LastMonth"
    },
    {
        "label": "This Year",
        "value": "ThisYear"
    },
    {
        "label": "Last Year",
        "value": "LastYear"
    },
    {
        "label": "Custom",
        "value": "Custom"
    }
];
const CustomDatePicker = ({activeDate, setActiveDate, customDate, setCustomDate, posEnd=true}) => {
    const [menuToggle, setMenuToggle] = useState(false);
    return (
        <UncontrolledDropdown isOpen={menuToggle} toggle={() => { setMenuToggle(!menuToggle); }}>
            <DropdownToggle
            type="button"
            tag="button"
            className="btn btn-outline-custom pe-5"
            >
            {activeDate ? activeDate.label+(activeDate.label=='Custom'?displayDate(customDate):'') : "Select Comparison"}
            <span className="rightBox">
                <span></span>
                <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
                >
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
            </span>
            </DropdownToggle>
            <DropdownMenu className="custom-menu-width" end={posEnd}>
                <Row>
                    <Col
                    sm={
                        activeDate &&
                        activeDate.label=='Custom'
                        ? 4
                        : 12
                    }
                    >
                    { dateTypes.map((item) => {
                        return (
                            <button
                            key={item.value}
                            onClick={() => {
                                if(item.value!='Custom'){
                                    setMenuToggle(false);
                                }
                                setActiveDate(item);
                            }}
                            type="button"
                            tabIndex="0"
                            role="menuitem"
                            className="dropdown-item"
                            >
                            {item.label}
                            </button>
                        );
                        })}
                    </Col>
                    { activeDate &&
                        activeDate.label=='Custom' && (
                        <Col sm={8}>
                        <Flatpickr
                            className="form-control border-0 dash-filter-picker shadow mw-auto"
                            placeholder=""
                            value={customDate}
                            onChange={([date, date2]) => {
                            if(date !== undefined && date2 !== undefined){
                                setMenuToggle(false);
                            }
                            setCustomDate([date, date2]);
                            }}
                            options={{
                                mode: "range",
                                dateFormat: "d M, Y",
                                inline: true,
                            }}
                        />
                        </Col>
                    )}
                </Row>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default CustomDatePicker;