import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_KIP_FILTER_API_DATA,
  KIP_FILTER_API_RESPONSE_SUCCESS,
  GET_KIP_WIDGET_API_DATA,
  KIP_WIDGET_API_RESPONSE_SUCCESS,
  GET_KIP_FORECAST_API_DATA,
  KIP_FORECAST_API_RESPONSE_SUCCESS,
  GET_KIP_SERVER_PERFORMANCE_API_DATA,
  KIP_SERVER_PERFORMANCE_API_RESPONSE_SUCCESS
} from "./actionType";
import {
  dashboardApiFilterSuccess,
  dashboardApiWidgetSuccess,
} from "./action";

//Include Both Helper File with needed methods
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";

function* getKIPFilterData({ payload: data }) {
  try {
    var response = yield call(postDataAPI, {
      op: POST_KPI_DASHBOARD.filter_op,
      serviceName: POST_KPI_DASHBOARD.serviceName,
    });
    if (response.status === "ok") {
      yield put(
        dashboardApiFilterSuccess(
          KIP_FILTER_API_RESPONSE_SUCCESS,
          response.data
        )
      );
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
}

function* getKIPWidgetData({ payload: data }) {
  try {
    data.serviceName = POST_KPI_DASHBOARD.serviceName;
    data.op = POST_KPI_DASHBOARD.widget_op;
    var response = yield call(postDataAPI, data);
    if (response.status === "ok") {
      yield put(
        dashboardApiWidgetSuccess(
          KIP_WIDGET_API_RESPONSE_SUCCESS,
          response.data
        )
      );
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
  }
}


export function* watchGetKIPFilterData() {
  yield takeEvery(GET_KIP_FILTER_API_DATA, getKIPFilterData);
  yield takeEvery(GET_KIP_WIDGET_API_DATA, getKIPWidgetData);
}

function* dashboardSaga() {
  yield all([fork(watchGetKIPFilterData)]);
}

export default dashboardSaga;