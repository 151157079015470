import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESSFUL,
  RESET_PASSWORD_FAILED
} from "./actionTypes"

export const resetPassword = (data, history) => {
  return {
    type: RESET_PASSWORD,
    payload: { data, history},
  }
}

export const resetPasswordSuccessful = data => {
  return {
    type: RESET_PASSWORD_SUCCESSFUL,
    payload: data,
  }
}

export const resetPasswordFailed = data => {
  return {
    type: RESET_PASSWORD_FAILED,
    payload: data,
  }
}