import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

//Include Both Helper File with needed methods
import {postDataAPI} from "../../../helpers/phpbackend_helper";

import {POST_REGISTER_DATA} from "../../../helpers/data_helper";

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(postDataAPI, {
      email: user.email,
      first_name:user.first_name,
      last_name:user.last_name,
      establishment:user.establishment,
      city:user.city,
      op:POST_REGISTER_DATA.op,
      serviceName:POST_REGISTER_DATA.serviceName
    });
    if (response.status === "ok") {
      yield put(registerUserSuccessful(response));
    } else {
      yield put(registerUserFailed(response));
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
