/* eslint-disable use-isnan */
/* eslint-disable default-case */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-const-assign */
/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect , useRef} from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  ModalFooter,
  Form,
  FormFeedback,
  Spinner
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import CourseItem from "./CourseItem";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { currencyFormatWithAlert } from "../../helpers/api_helper"
import { MENU_DESIGN } from "../../helpers/data_helper";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../Components/Common/Loader";
import CourseBadge from "../../Components/Common/CourseBadge"
//import { generatePDF } from 'react-to-pdf';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
const options = {
  page:{
    margin: Margin.SMALL
  }
}
const MenuDesign = () => {
  document.title = "Menu Engineering | " + process.env.REACT_APP_NAME;
  const [design, setDesign] = useState(false);
  //const { toPDF, targetRef } = usePDF({filename: design.title+".pdf",margin});
  const targetRef = useRef();
  const [ajaxLoader, setAjaxLoader] = useState(false);
  const [showModelPopup, setShowModelPopup] = useState(false);
  const [showDeleteModelPopup, setShowDeleteModelPopup] = useState(false);
  const [designList, setDesignList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [courseOptions, setCourseOptions] = useState({});
  const [itemDetail, setItemDetail] = useState({});  
  const [isApiLoading,setIsApiLoading] = useState(false);
  const optionOneDisabled = design!=false ? parseInt(design.option) < 1 : false;
  const optionTwoDisabled = design!=false ? parseInt(design.option) < 2 : false;
  const optionThreeDisabled = design!=false ? parseInt(design.option) < 3 : false;
  // High
  const [highest, sethighest] = useState('');
  const [highestcost, sethighestcost] = useState('');
  const [highestprofitability, sethighestprofitability] = useState('');
  const [highesItemname, sethighesItemname] = useState({});
  const [highthirtydaystrend, sethighthirtydaystrend] = useState('');
  // Most Profitable
  const [highestProfitPrice, setHighestProfitPrice] = useState('');
  const [highestProfitCost, setHighestProfitCost] = useState('');
  const [highestProfitPer, setHighestProfitPer] = useState('');
  const [highestProfitItems, setHighestProfitItems] = useState({});

  // Median
  //const [pricemedian, setpricemedian] = useState('');
  //const [costmedian, setcostmedian] = useState('');
  //const [profitmedian, setprofitmedian] = useState('');
  //const [medianItemName, setmedianItemName] = useState([]);
  //const [medianflag, setmedianflag] = useState('');

  // Low
  const [lowest, setlowest] = useState('');
  const [lowestcost, setlowestcost] = useState('');
  const [lowestprofitability, setlowestprofitability] = useState('');
  const [lowestitemname, setlowestitemname] = useState({});
  const [lowthirtydaystrend, setlowthirtydaystrend] = useState('');
  const [generatePdf, setGeneratePdf] = useState(false);

  // 30 days trend
  const [itemsSoldItemName, setitemsSoldItemName] = useState([]);    
  const [costthirtydaystrend, setCostthirtydaystrend] = useState('');
  const [thirtydaystrendProfitability, setThirtydaystrendProfitability] = useState('');
  const handleToastMessage = (type,message) => {
    switch(type){
      case "error":
        toast.error(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" });
        break;
      case "success":
        toast.success(message, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })
        break;
    }
    
  }
  useEffect(() => {
    if(design){
      const activeCourses=Object.keys(design.selected_course);
      setCourseList([...courseList.filter((course)=>activeCourses.includes(course.course_id)),...courseList.filter((course)=>!activeCourses.includes(course.course_id))]);
    }
    //setCourseList
  },[design])

  const [tableopacity, settableopacity] = useState(0.3);   
  const [calmsg , setcalmsg] = useState(false);   

  const handleUpdateOption = (course_id, option, item_id) => {

    settableopacity(0.3);
    setcalmsg(true);
    sethighest('');
    //setpricemedian('');
    //setcostmedian('');
    //setprofitmedian('');
    setlowest('');
    sethighestcost('');
    setlowestcost('');
    sethighestprofitability('');
    setlowestprofitability('');
    sethighesItemname([]);
    setlowestitemname([]);
    //setmedianItemName([]);
    sethighthirtydaystrend('');
    setCostthirtydaystrend('');
    setThirtydaystrendProfitability('');
    setlowthirtydaystrend('');
    setitemsSoldItemName([]);

    setHighestProfitPrice('');
    setHighestProfitCost('')
    setHighestProfitPer('');
    setHighestProfitItems({});

    const newOptions = { ...courseOptions };
    newOptions[course_id] = {
      ...courseOptions[course_id],
      [option]: item_id,
    };
    setCourseOptions({ ...newOptions });
    fetchItemsDetail(course_id,option,item_id);
  };

  const handleDesingChange = async (desing_id,newDesingList) => {
    let updatedDesingList=designList;
    if(newDesingList){
      updatedDesingList=newDesingList;
    }
    sethighest('');
    //setpricemedian('');
    //setcostmedian('');
    //setprofitmedian('');
    setlowest('');
    sethighestcost('');
    setlowestcost('');
    sethighestprofitability('');
    setlowestprofitability('');
    sethighesItemname([]);
    setlowestitemname([]);
    //setmedianItemName([]);
    sethighthirtydaystrend('');
    setCostthirtydaystrend('');
    setThirtydaystrendProfitability('');
    setlowthirtydaystrend('');  
    setitemsSoldItemName([]);

    setHighestProfitPrice('');
    setHighestProfitCost('')
    setHighestProfitPer('');
    setHighestProfitItems();

    const selctedDesing=updatedDesingList.find((des) => des.id == desing_id);
    if(selctedDesing){
      setDesign(selctedDesing);
      setAjaxLoader(true)
      const response = await postDataAPI({
        serviceName: MENU_DESIGN.serviceName,
        op: MENU_DESIGN.fetch_md_detail,
        md_id:desing_id
      })
      setAjaxLoader(false);
      if (response.status === "ok") {
        const designDetail=response.data.courses;
        const optionList={};
        Object.keys(designDetail).forEach((course_id)=>{
          optionList[course_id]={
            option1:designDetail[course_id][0]??"",
            option2:designDetail[course_id][1]??"",
            option3:designDetail[course_id][2]??""
          }
        })
        setCourseOptions(optionList);
        setItemDetail(response.data.item_details??{});

        if(response.data.item_details??{}){                    
          setTimeout(function() {
            document.getElementById('calbtn').click();
          }, 1000);
          
        }else{          
          console.log("item_details is empty");
        }

      } else {
        setCourseOptions({})
        handleToastMessage('error',response.message)
      }
    }else{
      setDesign(false);
      setCourseOptions({})
    }    
  };
  const getDesingData = async (desing_id) => {
    setAjaxLoader(true);
    const response=await postDataAPI({
      serviceName: MENU_DESIGN.serviceName,
      op: MENU_DESIGN.list_menu_design,
    });
    setAjaxLoader(false);
    if (response.status === "ok") {
      setDesignList(response.data);
      if(desing_id){
        handleDesingChange(desing_id,response.data);
      }
    } else {
      handleToastMessage('error',response.message)
    }
  }
  const fetchItemsDetail = async (course_id,option,item_id) => {
    setAjaxLoader(true);
    const response= await postDataAPI({
      serviceName: MENU_DESIGN.serviceName,
      op: MENU_DESIGN.fetch_items_detail,
      course_id:course_id,
      item_id:item_id
    })
    setAjaxLoader(false);
    if (response.status === "ok") {
      
      console.log(itemDetail); 
      setItemDetail((previous) => {
        const newItemDetail = { ...previous };
        newItemDetail[course_id] = {
          ...previous[course_id],
          [option]: Object.values(response.data).length>0?response.data:{},
        };
        console.log({...previous},newItemDetail);
        return newItemDetail;       
      })
    } else {
      handleToastMessage('error',response.message)
    }
  }
  const handleUpdateData = async () => {
    setAjaxLoader(true);
    let courseData={}
    if(Object.keys(courseOptions).length>0){
      Object.keys(courseOptions).forEach(course_id => {
        courseData[course_id]=[courseOptions[course_id].option1??"",courseOptions[course_id].option2??"",courseOptions[course_id].option3??""];
      });
    }
    const response= await postDataAPI({
      serviceName: MENU_DESIGN.serviceName,
      op: MENU_DESIGN.update_menu_design_items,
      md_id:design.id,
      course_id:JSON.stringify(courseData)
    })
    if (response.status === "ok") {
      handleToastMessage('success',response.message)
    } else {
      handleToastMessage('error',response.message)
    }
    setAjaxLoader(false);
  }
  const getCourseData=async ()=>{
    setAjaxLoader(true);
    const response= await postDataAPI({
      serviceName: MENU_DESIGN.serviceName,
      op: MENU_DESIGN.fetch_course_items,
    });
    setAjaxLoader(false);
    if (response.status === "ok") {
      setCourseList(Object.values(response.data));
    } else {
      handleToastMessage('error',response.message)
    }
  }
  useEffect(() => {
    getDesingData(false);
    getCourseData();    
    
  }, []);
  const handleSaveDesing = async (data) => {
    setIsApiLoading(true);
    const response = await postDataAPI({
      ...data,
      serviceName: MENU_DESIGN.serviceName,
      op: MENU_DESIGN.save_menu_design,
    });
    if (response.status === "ok") {
        ShowModelPopupToggle();
        handleToastMessage('success',response.message);
        getDesingData(response.data)
        //window.location.reload();
    } else {
      handleToastMessage('error',response.message)
    }
    setIsApiLoading(false);
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      md_title: "",
      course_id: [],
      option_cnt: 1,
    },
    validationSchema: Yup.object({
      md_title: Yup.string().required("Please enter desing"),
      option_cnt: Yup.string().required("Please select option count"),
      course_id: Yup.array().min(1,"Please select course"),
    }),
    onSubmit: (values) => {
      let courseData={}
      if(values.course_id.length>0){
        values.course_id.forEach(course_id => {
          courseData[course_id]=["","",""];
        });
      }
      const data={...values,course_id:JSON.stringify(courseData)}
      handleSaveDesing(data);
    },
  });

  const deleteValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      md_id: [],
      delete_text: "",
    },
    validationSchema: Yup.object({
      delete_text: Yup.string().test("checkMatch","Please type delete",value => value && 'delete'==value.toLowerCase()),
      md_id: Yup.array().min(1,"Please select desing"),
    }),
    onSubmit: (values) => {
      handleDeleteData(values.md_id)
    },
  });

  const ShowModelPopupToggle = () => {
    validation.resetForm();
    setShowModelPopup(!showModelPopup);
  };
  const ShowDeleteModelPopupToggle = () => {
    deleteValidation.resetForm();
    setShowDeleteModelPopup(!showDeleteModelPopup);
  }

  const handleDeleteData = async (md_id) => {
    setAjaxLoader(true);    
    const response= await postDataAPI({
      serviceName: MENU_DESIGN.serviceName,
      op: MENU_DESIGN.delete_design,
      md_id:md_id
    })
    getDesingData(false);
    if(md_id.includes(design?.id)){
      handleDesingChange(false);      
    }
    if (response.status === "ok") {
      handleToastMessage('success',response.message);
      if(md_id.includes(design?.id)){
        handleDesingChange(false);
      }
    } else {
      handleToastMessage('error',response.message)
    }
    ShowDeleteModelPopupToggle();
    setAjaxLoader(false);
  }
  
  const calulateDetails = () => {
    
    settableopacity(1);
    setcalmsg(false);
    // Call the function with the provided data
    const result = calculatePrices(itemDetail);

    //console.log(result);
   
    //Set values
    sethighest(result.Highest_Price);
    setlowest(result.Lowest_Price);

    sethighestcost(result.Highest_Cost);
    setlowestcost(result.Lowest_Cost);

    sethighestprofitability(result.Highest_Profitability);
    setlowestprofitability(result.Lowest_Profitability);

    sethighthirtydaystrend(result.Highest_Trend);
    setCostthirtydaystrend(result.Costthirtydaystrend);
    setThirtydaystrendProfitability(result.thirtydaystrendProfitability);
    //setmedianflag(result.medianflag);
    //setpricemedian(result.pricemedian);    
    //setcostmedian(result.costmedian);
    //setprofitmedian(result.profitmedian);

    sethighesItemname(result.highPriceItemName);
    setlowestitemname(result.lowPriceItemName);
    //setmedianItemName(result.medianPriceItemName);
    setitemsSoldItemName(result.itemsSoldItemName);    
    setHighestProfitPrice(result.mostProfitablePrice);
    setHighestProfitCost(result.mostProfitableCost)
    setHighestProfitPer(result.mostProfitablePer);
    setHighestProfitItems(result.mostProfitableItems);
  };

  // Function to calculate the sum of prices, and extract cost and profitability for highest and lowest prices
  const calculatePrices = (data) =>{
    let highestPriceSum = 0;
    let highestProfitability=0;
    let lowestPriceSum = 0;
    let highestCostSum = 0;
    let lowestCostSum = 0;
    let lowestProfitability=0;
    let highestTrendSum = 0;
    let highestTrendCost= 0;
    //let medianflag = 0;

    let highestitemsSoldSum = 0;
    let thirtydaystrendCost = 0;
    let thirtydaystrendPrice = 0;
    let thirtydaystrend_Profitability = 0;
    let highitemsSoldIds = [];
    let itemsSoldItemName = {};    

    let pricemedianarr = [];
    //let pricemedian = 0;
    let costmedian = 0;

    //let highPriceItemIds = [];
    let highPriceItemName = {};

    //let lowPriceItemIds = [];
    let lowPriceItemName = {};

    let medianPriceItemIds = [];
    let medianPriceItemName = [];
    let mostProfitableItems={};
    let mostProfitablePrice=0;
    let mostProfitableCost=0;
    let mostProfitablePer=0;
    
    
    Object.keys(data).forEach(courseId => {
        const options = Object.values(data[courseId]);
        const prices = options.map(option => parseFloat(option.price));
        const revenues = options.map(option => parseFloat(option.revenue));
        const itemsSold = options.map(option => parseFloat(option.item_sold));
        const item_ids = options.map(option => parseFloat(option.item_id));
        const maxProfit = Math.max(...options.map(option => parseFloat(option.profit_percentage)));
        const maxPrice = Math.max(...prices);
        const minPrice = Math.min(...prices);
        const maxItemSold = Math.max(...itemsSold);

        mostProfitableItems[courseId]=[];
        highPriceItemName[courseId]=[];
        lowPriceItemName[courseId]=[];
        itemsSoldItemName[courseId]=[];
        let findhighest=false;
        let findlowest=false;
        let findpcRecommended=false;
        let findmostProfitable=false;
        options.forEach(option => {
          if(parseFloat(option.profit_percentage)==maxProfit){
            if(findmostProfitable==false){
              mostProfitablePrice+=parseFloat(option.price);
              mostProfitableCost+=parseFloat(option.cost);
            }
            findmostProfitable=true;
            mostProfitableItems[courseId].push(option);
          }
          if(parseFloat(option.price)==maxPrice){
            if(findhighest==false){
              highestPriceSum+=maxPrice;
              highestCostSum+=parseFloat(option.cost);
            }
            findhighest=true;
            highPriceItemName[courseId].push(option);
          }
          if(parseFloat(option.price)==minPrice){
            if(findlowest==false){
              lowestPriceSum+=minPrice;
              lowestCostSum+=parseFloat(option.cost);
            }
            findlowest=true;
            lowPriceItemName[courseId].push(option);
          }
          if(parseFloat(option.item_sold)==maxItemSold){
            if(findpcRecommended==false){
              highestTrendSum+=parseFloat(option.price);
              highestTrendCost+=parseFloat(option.cost);
            }
            findpcRecommended=true;
            itemsSoldItemName[courseId].push(option);
          }
        })
  
    });
    
    highestProfitability = ((parseFloat(highestPriceSum) - parseFloat(highestCostSum)) / parseFloat(highestPriceSum)) * 100;
    lowestProfitability = ((parseFloat(lowestPriceSum) - parseFloat(lowestCostSum)) / parseFloat(lowestPriceSum)) * 100;
    mostProfitablePer= ((parseFloat(mostProfitablePrice) - parseFloat(mostProfitableCost)) / parseFloat(mostProfitablePrice)) * 100;
    thirtydaystrend_Profitability=((parseFloat(highestTrendSum) - parseFloat(highestTrendCost)) / parseFloat(highestTrendSum)) * 100;
    //const highestTrend = highestTrendSum;

    // console.log("highestProfitability",highestProfitability);
        
    return {
        Highest_Price: highestPriceSum,
        Lowest_Price: lowestPriceSum,
        Highest_Cost: highestCostSum,
        Lowest_Cost: lowestCostSum,
        Highest_Profitability: highestProfitability != '-Infinity' && !isNaN(highestProfitability) ? highestProfitability.toFixed(2) : 0,
        Lowest_Profitability: lowestProfitability != '-Infinity' && !isNaN(lowestProfitability) ? lowestProfitability.toFixed(2) : 0,        
        Highest_Trend: highestTrendSum,
        //medianflag: medianflag,
        //pricemedian: pricemedian,
        //costmedian: costmedian,
        //profitmedian: profitmedian,
        highPriceItemName: highPriceItemName,
        lowPriceItemName: lowPriceItemName,
        //medianPriceItemName: merged_medianPriceItemName,
        itemsSoldItemName: itemsSoldItemName,
        Costthirtydaystrend:highestTrendCost,        
        thirtydaystrendProfitability: thirtydaystrend_Profitability != '-Infinity' && !isNaN(thirtydaystrend_Profitability) ? thirtydaystrend_Profitability.toFixed(2) : 0,        
        mostProfitableItems:mostProfitableItems,
        mostProfitablePrice:mostProfitablePrice,
        mostProfitableCost:mostProfitableCost,
        mostProfitablePer: mostProfitablePer != '-Infinity' && !isNaN(mostProfitablePer) ? mostProfitablePer.toFixed(2) : 0
    };
  }  

  // Calculate count of options in each parent object
  const checkObjectCountEquality = (data) => {
    // Extracting keys (course IDs) from the main object
    const courseIds = Object.keys(data);

    // Checking if the count of objects is the same for all courses
    const isCountEqual = courseIds.every(courseId => {
        const options = data[courseId];
        const optionCount = Object.keys(options).length;
        if(optionCount == 3){
          return true;
        }else{
          return false;
        }        
    });

    return isCountEqual;
  }

  // Calculate Median Function
  const calmedian = (values) =>{

    if (values.length === 0) {
      throw new Error('Input array is empty');
    }
  
    // Sorting values, preventing original array
    // from being mutated.
    values = [...values].sort((a, b) => a - b);
  
    const half = Math.floor(values.length / 2);
  
    return (values.length % 2
      ? values[half]
      : (values[half - 1] + values[half]) / 2
    );
  
  }
  const printDocument =() => {
    setGeneratePdf(true);
  }
  useEffect(()=>{
    if(generatePdf){
      generatePDF(targetRef, {filename: design.title+'.pdf',...options});      
      setGeneratePdf(false);
    }
  },[generatePdf])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Menu Design" pageTitle="Dashboard" />
          <Row>
            <Col sm={12}>
              <Card>
                <CardHeader className="align-items-center d-flex justify-content-between">
                  <div className="flex-shrink-0 d-flex">
                    <select
                      className="form-select"
                      value={design.id??"0"}
                      id="design"
                      onChange={(event)=>{ handleDesingChange(event.target.value,false)}}
                    >
                      <option value="0">Select Design</option>
                      {designList.length > 0 &&
                        designList.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          );
                        })}
                    </select>                    
                  </div>
                  <div className="flex-shrink-0">
                    <Button
                      color="primary"
                      className="custom-toggle"
                      onClick={ShowModelPopupToggle}
                    >
                      <span className="icon-on">
                        <i className="ri-add-line align-bottom me-1"></i> Add
                      </span>
                    </Button>
                    <Button
                      color="danger"
                      className="custom-toggle"
                      onClick={ShowDeleteModelPopupToggle}                      
                      style={{marginLeft: '15px'}}
                    >
                      <span className="icon-on">Delete</span>
                    </Button>
                    {design && 
                    <Button
                      color="secondary"
                      className="custom-toggle"
                      onClick={printDocument}                      
                      style={{marginLeft: '15px'}}
                    >
                      <span className="icon-on">Download PDF</span>
                    </Button> }
                  </div>
                </CardHeader>
                <CardBody id="divToPrint">
                  <div ref={targetRef}>
                  {design && (
                    <>
                      <div className="position-relative">
                      {ajaxLoader && <Loader />}
                      <Table className={ajaxLoader?"table-bordered mb-3 opacity-50":"table-bordered mb-3"} style={{textAlign: 'center'}}>
                        <thead className="table-light">
                          <tr>
                            <th scope="col">Course</th>
                            <th
                              scope="col"
                              className={
                                optionOneDisabled
                                  ? "table-primary text-muted"
                                  : ""
                              }
                            >
                              Option 1
                            </th>
                            <th
                              scope="col"
                              className={
                                optionTwoDisabled
                                  ? "table-primary text-muted"
                                  : ""
                              }
                            >
                              Option 2
                            </th>
                            <th
                              scope="col"
                              className={
                                optionThreeDisabled
                                  ? "table-primary text-muted"
                                  : ""
                              }
                            >
                              Option 3
                            </th>
                            <th scope="col">Range</th>
                          </tr>
                        </thead>
                        <tbody>
                          {courseList.map((course) => {
                            return (
                              <CourseItem
                                design={design}
                                key={course.course_id}
                                itemDetail={itemDetail}
                                optionOneDisabled={optionOneDisabled}
                                optionTwoDisabled={optionTwoDisabled}
                                optionThreeDisabled={optionThreeDisabled}
                                course={course}
                                courseOptions={courseOptions}
                                handleUpdateOption={handleUpdateOption}
                              />
                            );
                          })}
                        </tbody>
                      </Table>
                      </div>
                      {generatePdf==false && 
                      <Row className="mb-3">
                        <Col sm={6} className="text-start">
                          <h2>Result/Totals</h2>
                        </Col>
                        <Col sm={6} className="text-end">
                          {calmsg && <span style={{fontSize: '14px', marginRight: '10px', color: '#f06548'}}><b>Please click to calculate</b></span>}
                          {design && <Button disabled={ajaxLoader} onClick={handleUpdateData} color="primary" style={{marginRight:"10px"}}>Save</Button>}
                          <Button color="secondary" id="calbtn" onClick={calulateDetails}>Calculate</Button>
                        </Col>
                      </Row>
                      }
                      <Table className="table-bordered mb-3" style={{textAlign: 'center', opacity: tableopacity}}>
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{width:"100px"}}></th>
                            <th scope="col" style={{width: '20%'}}>Highest</th>
                            {/* <th scope="col" style={{width: '20%'}}>Average</th> */}
                            <th scope="col" style={{width: '20%'}}>Lowest</th>
                            <th scope="col">Most Profitable</th>
                            <th scope="col">PC Recommended</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="col">Price</th>
                            <td>{currencyFormatWithAlert(highest)}</td>
                            {/* <td>{(highest+lowest)/2}</td> */}
                            <td>{currencyFormatWithAlert(lowest)}</td>
                            <td>{currencyFormatWithAlert(highestProfitPrice)}</td>
                            <td>{currencyFormatWithAlert(highthirtydaystrend)}</td>
                          </tr>
                          <tr>
                            <th scope="col">Cost</th>
                            <td>{currencyFormatWithAlert(highestcost)}</td>
                            {/* <td>{(highestcost+lowestcost)/2}</td> */}
                            <td>{currencyFormatWithAlert(lowestcost)}</td>
                            <td>{currencyFormatWithAlert(highestProfitCost)}</td>
                            <td>{currencyFormatWithAlert(costthirtydaystrend)}</td>
                          </tr>
                          <tr>
                            <th scope="col">Profit</th>
                            <td>{currencyFormatWithAlert(highest-highestcost)}</td>
                            {/* <td>{((parseFloat(highestprofitability)+parseFloat(lowestprofitability))/2).toFixed(2)}%</td> */}
                            <td>{currencyFormatWithAlert(lowest-lowestcost)}</td>
                            <td>{currencyFormatWithAlert(highestProfitPrice-highestProfitCost)}</td>
                            <td>{currencyFormatWithAlert(highthirtydaystrend-costthirtydaystrend)}</td>
                          </tr>
                          <tr>
                            <th scope="col">Profit(%)</th>
                            <td>{!isNaN(parseFloat(highestprofitability)) && parseFloat(highestprofitability) !== -Infinity ? parseFloat(highestprofitability).toFixed(2) : '0.00'}%</td>
                            {/* <td>{((parseFloat(highestprofitability)+parseFloat(lowestprofitability))/2).toFixed(2)}%</td> */}
                            <td>{!isNaN(parseFloat(lowestprofitability)) && parseFloat(lowestprofitability) !== -Infinity ? parseFloat(lowestprofitability).toFixed(2) : '0.00'}%</td>
                            <td>{!isNaN(parseFloat(highestProfitPer)) && parseFloat(highestProfitPer) !== -Infinity ? parseFloat(highestProfitPer).toFixed(2) : '0.00'}%</td>
                            <td>{!isNaN(parseFloat(thirtydaystrendProfitability)) && parseFloat(thirtydaystrendProfitability) !== -Infinity ? parseFloat(thirtydaystrendProfitability).toFixed(2) : '0.00'}%</td>
                          </tr>
                          {Object.keys(highesItemname).map((course_id, index) => {
                            const courseData=courseList.find((course)=>course_id==course.course_id);
                            return <tr key={index}>
                              {index==0 && <th scope="col" rowSpan={Object.keys(highesItemname).length}>Items</th>}
                              <td>
                                <h5 className="mb-3"><CourseBadge course_name={courseData.course_name} /></h5>
                                {highesItemname[course_id].map((item, idx) => (
                                    <div key={idx}>{item.item_name}</div>
                                ))}
                              </td>
                              {/* {index==0 && <td rowSpan={Object.keys(highesItemname).length}></td>} */}
                              <td>
                                {lowestitemname[course_id] && <>
                                  <h5 className="mb-3"><CourseBadge course_name={courseData.course_name} /></h5>
                                  {lowestitemname[course_id].map((item, idx) => (
                                      <div key={idx}>{item.item_name}</div>
                                  ))} </>
                                }
                              </td>
                              <td>
                                  {highestProfitItems[course_id] && <>
                                    <h5 className="mb-3"><CourseBadge course_name={courseData.course_name}/></h5>
                                    {highestProfitItems[course_id].map((item, idx) => (
                                        <div key={idx}>{item.item_name}</div>
                                    ))} </>
                                    }                          
                              </td>
                              <td>
                                  { itemsSoldItemName[course_id] && <>
                                    <h5 className="mb-3"><CourseBadge course_name={courseData.course_name} extraStyle={{marginRight: '10px'}}/></h5>
                                    {itemsSoldItemName[course_id].map((item, idx) => (
                                        <div key={idx}>{item.item_name}</div>
                                    ))} </>
                                    }                          
                              </td>
                            </tr>        
                          })}                  
                        </tbody>
                      </Table>
                    </>
                  )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        isOpen={showModelPopup}
        toggle={() => {
          ShowModelPopupToggle();
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            ShowModelPopupToggle(false);
          }}
          className="mb-2"
        >
          Add design
        </ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
        <ModalBody>
            <Row className="mb-3">
              <Col sm={6} lg={4}>
                <Label htmlFor="md_title" className="form-label">
                  Design name
                </Label>
              </Col>
              <Col sm={6} lg={8}>
                <Input
                  type="text"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  name="md_title"
                  id="md_title"
                  value={validation.values.md_title || ""}
                  invalid={
                    validation.touched.md_title && validation.errors.md_title ? true : false
                 }
                />
                {validation.touched.md_title && validation.errors.md_title ? (
                    <FormFeedback type="invalid">{validation.errors.md_title}</FormFeedback>
                ) : null}
              </Col>
            </Row>
            {/*
                <Row className="mb-3">
                    <Col sm={6} lg={4}><Label htmlFor="copy_from" className="form-label">Copy from</Label></Col>
                    <Col sm={6} lg={8}>
                        <select
                        className="form-select"
                        id="copy_from"
                        >
                        <option value="0">Select Design</option>
                        {
                            designList.length > 0 &&
                            designList.map((item) => {
                                return (
                                    <option key={item.id} value={item.id}>
                                    {item.title}
                                    </option>
                                );
                            })
                        }
                        </select>
                    </Col>
                </Row>
                */
            }
            <Row className="mb-3">
              <Col sm={6} lg={4}>
                <Label htmlFor="option" className="form-label">
                  Option per course
                </Label>
              </Col>
              <Col sm={6} lg={8}>
                {/* <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="option_cnt"
                    id="option1"
                    value="1"
                    checked={validation.values.option_cnt == 1}
                  />
                  <Label className="form-check-label" htmlFor="option1">
                    Single Option Only
                  </Label>
                </div> */}
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    type="radio"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="option_cnt"
                    id="option2"
                    value="2"
                    checked={validation.values.option_cnt == 2}
                  />
                  <Label className="form-check-label" htmlFor="option2">
                  Option One and Two
                  </Label>
                </div>
                <div className={(validation.touched.md_title && validation.errors.md_title) ? "form-check is-invalid" : "form-check form-check-inline"}>
                  <Input
                    className="form-check-input"
                    type="radio"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    name="option_cnt"
                    id="option3"
                    value="3"
                    checked={validation.values.option_cnt == 3}
                  />
                  <Label className="form-check-label" htmlFor="option3">
                    Option One, Two, and Three
                  </Label>
                </div>
                {validation.touched.option_cnt && validation.errors.option_cnt ? (
                    <FormFeedback type="invalid">{validation.errors.option_cnt}</FormFeedback>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col sm={6} lg={4}>
                <Label htmlFor="courses" className="form-label">
                  Choose Courses
                </Label>
              </Col>
              <Col sm={6} lg={8}>
                <div className={(validation.touched.course_id && validation.errors.course_id) ? "is-invalid" : ""}>                    
                    {courseList.map((course) => {
                    return (
                        <div key={course.course_id} className="form-check mb-2">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id={`course-${course.course_id}`}
                            value={course.course_id}
                            name="course_id"
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            checked={validation.values.course_id.includes(course.course_id)}
                        />
                        <Label
                            className="form-check-label"
                            htmlFor={`course-${course.course_id}`}
                        >
                            {course.course_name}
                        </Label>
                        </div>
                    );
                    })}
                </div>                    
                {validation.touched.course_id && validation.errors.course_id ? (
                    <FormFeedback type="invalid">{validation.errors.course_id}</FormFeedback>
                ) : null}
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={ShowModelPopupToggle}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={isApiLoading}>
            Save {isApiLoading && <Spinner className="ms-1" size="sm"></Spinner>}
          </Button>
        </ModalFooter>        
        </Form>
      </Modal>

      <Modal
        size="md"
        isOpen={showDeleteModelPopup}
        toggle={() => {
          ShowDeleteModelPopupToggle();
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            ShowDeleteModelPopupToggle(false);
          }}
          className="mb-2"
        >
          Delete Menu Design
        </ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              deleteValidation.handleSubmit();
              return false;
            }}
            action="#"
          >
        <ModalBody style={{height: '350px'}}>

            <Row>
              {/* <Col sm={6} lg={4}>
                <Label htmlFor="courses" className="form-label">
                  Courses
                </Label>
              </Col> */}
              <Col sm={12} lg={12} style={{height: '220px', maxHeight: '300px', overflowY: 'auto'}}>
                <div className={(deleteValidation.touched.md_id && deleteValidation.errors.md_id) ? "is-invalid" : ""}>                    
                    {designList.map((item) => {
                    return (
                        <div key={item.id} className="form-check mb-2">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id={`course-${item.id}`}
                            value={item.id}
                            name="md_id"
                            onBlur={deleteValidation.handleBlur}
                            onChange={deleteValidation.handleChange}
                            checked={deleteValidation.values.md_id.includes(item.id)}
                        />
                        <Label
                            className="form-check-label"
                            htmlFor={`course-${item.id}`}
                        >
                            {item.title}
                        </Label>
                        </div>
                    );
                    })}
                </div>                    
                {deleteValidation.touched.md_id && deleteValidation.errors.md_id ? (
                    <FormFeedback type="invalid">{deleteValidation.errors.md_id}</FormFeedback>
                ) : null}
              </Col>
              <Col sm={12} lg={8} className="mt-4">
                <Label>Confirm by typing <strong className="text-danger">delete</strong> below</Label>
                <Input
                  type="text"
                  className="form-control"
                  onChange={deleteValidation.handleChange}
                  onBlur={deleteValidation.handleBlur}
                  name="delete_text"
                  id="delete_text"
                  value={deleteValidation.values.delete_text || ""}
                  invalid={
                    deleteValidation.touched.delete_text && deleteValidation.errors.delete_text ? true : false
                 }
                />
                {deleteValidation.touched.delete_text && deleteValidation.errors.delete_text ? (
                    <FormFeedback type="invalid">{deleteValidation.errors.delete_text}</FormFeedback>
                ) : null}
              </Col>
            </Row>
        </ModalBody>   
        <ModalFooter>
          <Button color="light" onClick={ShowDeleteModelPopupToggle}>
            Cancel
          </Button>
          <Button color="danger" type="submit" disabled={isApiLoading}>
            Delete {isApiLoading && <Spinner className="ms-1" size="sm"></Spinner>}
          </Button>
        </ModalFooter>           
        </Form>
      </Modal>

      <ToastContainer />
    </React.Fragment>
  );
};

export default MenuDesign;
