import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import UnallocatedChecksModal from "../Common/UnallocatedChecksModal"
import {getDateByName, convertDateFormate} from "../../helpers/api_helper";
import { postDataAPI } from "../../helpers/phpbackend_helper";

const BreadCrumb = ({ title, pageTitle, handleR,efresh,showAlert, activeDate,customDate }) => {
  const [unallocatedChecksModal,setUnallocatedChecksModal]=useState(false);
  const [checklist,setCheckList]=useState([]);
  const [totalChecks,setTotalChecks]=useState(0);

  const getUnmappedChecks = async () =>{
    const reponse = await postDataAPI({
        dateFilter: activeDate.value==='Custom'?{from:convertDateFormate(customDate[0]),to:convertDateFormate(customDate[1])}:getDateByName(activeDate.value),
        serviceName: 'service_period_data',
        op: 'get_unmapped_sp_id'
    });
    setCheckList(reponse.data.list)
    setTotalChecks(reponse.data.total_rows)
  }
  useEffect(()=>{
    if(showAlert){
      getUnmappedChecks();
    }
  },[activeDate,customDate])
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h4 className="mb-sm-0 pe-3">{title}</h4>              
                {/* <button
                    onClick={handleRefresh}
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >                                       
                    <i className='bx bx-refresh fs-2'></i>
                </button> */}
            </div>
            <div className="page-title-right d-flex align-items-center">
                { showAlert && 
                <button
                  onClick={()=>setUnallocatedChecksModal(true)}
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle me-5"
                >                                       
                    <i className='bx bx-bell fs-2'></i>
                    <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{totalChecks}</span>
                </button>
              }
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#">{pageTitle}</Link>
                </li>
                <li className="breadcrumb-item active">{title}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
      {unallocatedChecksModal && <UnallocatedChecksModal getUnmappedChecks={getUnmappedChecks} activeDate={activeDate} customDate={customDate} checklist={checklist} unallocatedChecksModal={unallocatedChecksModal} setUnallocatedChecksModal={setUnallocatedChecksModal} />}
    </React.Fragment>
  );
};

export default BreadCrumb;
