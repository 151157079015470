const PerformanceBadge = (props) => {
  const {title}=props;

  if(title=="")
    return <></>;

  return (
    <span style={{fontSize: '12px'}}
        className={`badge bg-${title === "Top performer"
          ? "success"
          : title === "Bottom performer"
            ? "danger"
            : title === "Improvement needed"
              ? "warning"
                  : "light text-dark"
          }
                    `}
      >
        {title}
      </span>
    )
}
export default PerformanceBadge;
