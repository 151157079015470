/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, createRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonGroup,
  Input,
  Label,
  Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import { currencyFormat, currencyFormatWithoutDecimal, currencyFormatWithAlert } from "../../helpers/api_helper";
import DataTable from "react-data-table-component";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import Loader from "../../Components/Common/Loader";
import { getDateByName, convertDateFormate } from "../../helpers/api_helper";
import CustomPagination from "../../Components/Common/CustomPagination";
import classnames from "classnames";
import CourseBadge from "../../Components/Common/CourseBadge";
import PerformanceBadge from "../../Components/Common/PerformanceBadge";

const itemsPerPage = 50;

const PortfolioAnalysis = ({
  globalDate,
  rightFilter,
  comparisonDate,
  globalCustomDate,
  activeComparisonData,
  mealPeriod,
  defaultCourse,
  selectedPage,
  setSelectedPage,
  recalculate,
  menuItemData,
}) => {
  const [showModelPopup, setShowModelPopup] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageCount_ServerTable, setPageCount_ServerTable] = useState(0);
  const [sellingItemAjax, setSellingItemAjax] = useState(true);
  const [sellingItem, setSellingItem] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [ItemsData, setItemsData] = useState([]);  
  const [showRecommendModal, setShowRecommendModal] = useState(false);
  const [showVisualModal, setShowVisualModal] = useState(false);
  const [selectedPage_ServerTable, setselectedPage_ServerTable] = useState(0);
  const ShowModelPopupToggle = () => {
    setShowModelPopup(!showModelPopup);
  };
  const [menuItemAjaxModal, setMenuItemAjaxModal] = useState(true);

  useEffect(() => {
    setSellingItemAjax(true);
    let leftFilterData = false;
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      handleSort(SortColumn, 'initial');
    }
  }, [
    globalDate,
    globalCustomDate,
    mealPeriod,
    defaultCourse,
    selectedPage,
    recalculate,
  ]);

  /******** Sorting **** Starts*****/
  const [SortColumn, setsortColumn] = useState('');
  const [isRotatedname, setIsRotatedname] = useState(false);
  const [isRotatedsold, setIsRotatedsold] = useState(false);
  const [isRotatedsell_price, setIsRotatedsell_price] = useState(false);
  const [isRotateditem_cost, setIsRotateditem_cost] = useState(false);
  const [isRotatedsales_mix, setIsRotatedsales_mix] = useState(false);
  const [isRotatedrevenueMix, setIsRotatedrevenueMix] = useState(false);
  const [isRotatedcost, setIsRotatedcost] = useState(false);
  const [isRotateditem_gross_profit, setIsRotateditem_gross_profit] = useState(false);  
  const [courseModalDetailAjax, setcourseModalDetailAjax] = useState(true);  
  const [limitdroddown, setlimitdroddown] = useState('50');
  const [SortType, setSortType] = useState('ASC');

  const handleSort = (value, callfrom) => {
    let sorttypefinalvalue;
    if (callfrom === 'initial') {
      sorttypefinalvalue = localStorage.getItem('Portfolio_analysis_SortType');
    } else {
      if (localStorage.getItem('Portfolio_analysis_SortType') === '') {
        localStorage.setItem('Portfolio_analysis_SortType', 'ASC');
        sorttypefinalvalue = 'ASC';
      } else {
        if (localStorage.getItem('Portfolio_analysis_SortType') === 'ASC') {
          localStorage.setItem('Portfolio_analysis_SortType', 'DESC');
          sorttypefinalvalue = 'DESC';
        } else {
          localStorage.setItem('Portfolio_analysis_SortType', 'ASC');
          sorttypefinalvalue = 'ASC';
        }
      }
    }

    setsortColumn(value);

    if (value === 'avg_check') {
      setIsRotatedsold(!isRotatedsold);
    }
    if (value === 'revenue') {
      setIsRotatedsell_price(!isRotatedsell_price);
    }
    if (value === 'sales_mix') {
      setIsRotatedsales_mix(!isRotatedsales_mix);
    }
    if (value === 'revenue_mix') {
      setIsRotatedrevenueMix(!isRotatedrevenueMix);
    }
    if (value === 'cost') {
      setIsRotatedcost(!isRotatedcost);
    }
    if (value === 'gross_profit') {
      setIsRotateditem_gross_profit(!isRotateditem_gross_profit);
    }

    setSellingItemAjax(true);
    let leftFilterData = false;
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {         
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.performance_analysis,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: 0,
        recalculate: false,
        sort_column: '',
        sort_type: ''
      }).then((response) => {
        setSellingItemAjax(false);
        if (response.status === "ok") {
                    
          let setarr = [];                     
          let item_sold;
          let revenue;
          let cost;
          let avg_check;
          let sales_mix;
          let revenue_mix;
          let gross_profit;

          Object.keys(response.data.totalRow.total).forEach(key => {
            // console.log(`${key}: ${response.data.totalRow.total[key]}`);            
            if(key === 'item_sold'){
              item_sold = response.data.totalRow.total[key]
            }
            if(key === 'revenue'){
              revenue = response.data.totalRow.total[key]
            }
            if(key === 'cost'){
              cost = response.data.totalRow.total[key]
            }
            if(key === 'avg_check'){
              avg_check = response.data.totalRow.total[key]
            }
            if(key === 'sales_mix'){
              sales_mix = response.data.totalRow.total[key]
            }
            if(key === 'revenue_mix'){
              revenue_mix = response.data.totalRow.total[key]
            }
            if(key === 'gross_profit'){
              gross_profit = response.data.totalRow.total[key]
            }            
          });

          setarr.unshift({
            "performance_group": "Total",
            "item_sold": item_sold,
            "revenue": revenue,
            "cost": cost,
            "avg_check": avg_check,
            "sales_mix": sales_mix,
            "revenue_mix": revenue_mix,
            "gross_profit": gross_profit
          });

          for(let i=0;i<response.data.data.length;i++){            
            setarr.push(response.data.data[i]);
          }     
          console.log(setarr)     
          setSellingItem(setarr);

          // setPageCount(Math.ceil(response.data.totalData / limitdroddown));
          // setPageCount(Math.ceil(sellingItem.length / limitdroddown));

        } else {
          console.log(response);
          // setPageCount(0);
        }
      });
    }

  };
  /************END Sorting**************/

  /*********** Item Name Modal ********/
  const [justifyTab, setjustifyTab] = useState("course");
  const justifyToggle = (tab) => {
    if (justifyTab !== tab) {
      setjustifyTab(tab);
    }
  };
  const [modalItemName, setModalItemName] = useState(false);
  const [modalItem, setModalItem] = useState('');
  const [CorrelationTableMsg, setCorrelationTableMsg] = useState('');
  function togItemName() {
    setModalItemName(!modalItemName);
  }
  let [ItemNameArray, setItemNameArray] = useState([]);
  const [menuItemDataModal, setMenuItemDataModal] = useState({
    Courses: [],
    MenuItems: [],
  });
  const [item_revenue_chkcount, setitem_revenue_chkcount] = useState([]);

  const handleItemName = (item) => {    
    setModalItem(item);
    setModalItemName(true);
    for (let i = ItemNameArray.length - 1; i >= 0; i--) {
      ItemNameArray.splice(i, 1);
    }
    ItemNameArray.push(item);
    let leftFilterData = false;

    /****** CALL RIght Graph API ******/
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      setcourseModalDetailAjax(true);
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.performance_analysis_by_category,        
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: 0,
        recalculate: false,
        sort_column: '',
        sort_type: '',
        pgroup_name: item.performance_group
      }).then((response) => {
        setcourseModalDetailAjax(false);
        if (response.status === "ok") {          
          
          let setCategoryarr = [];
          let setarr = [];                     
          let item_sold;
          let revenue;
          let cost;
          let avg_check;
          let sales_mix;
          let revenue_mix;
          let gross_profit;

          Object.keys(response.data.totalRow.total).forEach(key => {
            // console.log(`${key}: ${response.data.totalRow.total[key]}`);            
            if(key === 'item_sold'){
              item_sold = response.data.totalRow.total[key]
            }
            if(key === 'revenue'){
              revenue = response.data.totalRow.total[key]
            }
            if(key === 'cost'){
              cost = response.data.totalRow.total[key]
            }
            if(key === 'avg_check'){
              avg_check = response.data.totalRow.total[key]
            }
            if(key === 'sales_mix'){
              sales_mix = response.data.totalRow.total[key]
            }
            if(key === 'revenue_mix'){
              revenue_mix = response.data.totalRow.total[key]
            }
            if(key === 'gross_profit'){
              gross_profit = response.data.totalRow.total[key]
            }            
          });

          setarr.unshift({
            "name": "Total",
                  "item_sold": item_sold,
                  "revenue": revenue,
                  "cost": cost,
                  "avg_check": avg_check,
                  "sales_mix": sales_mix,
                  "revenue_mix": revenue_mix,
                  "gross_profit": gross_profit,
                  "performance_group": ''
          });

          for(let i=0;i<response.data.data.itemResult.length;i++){            
            setarr.push(response.data.data.itemResult[i]);                                 
          }
          setItemsData(setarr);

          setCategoryarr.unshift({
            "course_name": "Total",
            "item_sold": item_sold,
            "revenue": revenue,
            "cost": cost,
            "avg_check": avg_check,
            "sales_mix": sales_mix,
            "revenue_mix": revenue_mix,
            "gross_profit": gross_profit,
            "performance_group": ''
          });
          for(let i=0;i<response.data.data.courseResult.length;i++){                         
              setCategoryarr.push(response.data.data.courseResult[i]);                  
          }                                        
          setCategoryData(setCategoryarr);          

        } else {
          console.log(response);
        }
      });
    } else {
      console.log(1);
    }
    /***** END *****/

  }
  /*********** END **********/

  const handleLimit = (e) => {
    setlimitdroddown(e.target.value);
    if (e.target.value !== '') {
      setSellingItemAjax(true);
      let leftFilterData = false;
      if (
        globalDate.value !== "Custom" ||
        (globalDate.value === "Custom" &&
          globalCustomDate[0] &&
          globalCustomDate[1])
      ) {
        leftFilterData =
          globalDate.value === "Custom"
            ? {
              from: convertDateFormate(globalCustomDate[0]),
              to: convertDateFormate(globalCustomDate[1]),
            }
            : getDateByName(globalDate.value);
      }
      if (leftFilterData) {
        postDataAPI({
          dateFilter: leftFilterData,
          serviceName: POST_MENU_OPTIMIZATION.serviceName,
          op: POST_MENU_OPTIMIZATION.performance_analysis,
          mealPeriod: mealPeriod,
          course: defaultCourse,
          page: 0,
          recalculate: false,
          sort_column: '',
          sort_type: ''                    
        }).then((response) => {
          setSellingItemAjax(false);
          if (response.status === "ok") {

            let setarr = [];                     
            let item_sold;
            let revenue;
            let cost;
            let avg_check;
            let sales_mix;
            let revenue_mix;
            let gross_profit;

            Object.keys(response.data.totalRow.total).forEach(key => {
              // console.log(`${key}: ${response.data.totalRow.total[key]}`);            
              if(key === 'item_sold'){
                item_sold = response.data.totalRow.total[key]
              }
              if(key === 'revenue'){
                revenue = response.data.totalRow.total[key]
              }
              if(key === 'cost'){
                cost = response.data.totalRow.total[key]
              }
              if(key === 'avg_check'){
                avg_check = response.data.totalRow.total[key]
              }
              if(key === 'sales_mix'){
                sales_mix = response.data.totalRow.total[key]
              }
              if(key === 'revenue_mix'){
                revenue_mix = response.data.totalRow.total[key]
              }
              if(key === 'gross_profit'){
                gross_profit = response.data.totalRow.total[key]
              }            
            });

            for(let i=0;i<response.data.data.length;i++){
              if(i === 0){
                setarr[0] = (
                  {
                    "performance_group": "Total",
                    "item_sold": item_sold,
                    "revenue": revenue,
                    "cost": cost,
                    "avg_check": avg_check,
                    "sales_mix": sales_mix,
                    "revenue_mix": revenue_mix,
                    "gross_profit": gross_profit
                  }
                );
              }else{
                setarr[i] = response.data.data[i];
              }            
            }          
            setSellingItem(setarr);
            
          } else {
            console.log(response);
            // setPageCount(0);
          }
        });
      }
    }

  }

  /******** Show and Hide Columns *********/
  const [sortColumn, SetSortColumn] = useState('');
  const columnDef = [
    {
      name: ' ',
      key: 'performance_group',
      className: "text-start",
    },
    {
      name: 'Item Sold',
      key: 'item_sold'
    },
    {
      name: 'Avg Check',
      key: 'avg_check',
      sort: true,
    },
    {
      name: 'Revenue',
      key: 'revenue',
      sort: true,
    },
    {
      name: 'Sales Mix',
      key: 'sales_mix',
    },
    {
      name: 'Revenue Mix',
      key: 'revenue_mix',
    },
    {
      name: 'Cost',
      key: 'cost',
      sort: true,
    },
    {
      name: 'Item Gross Profit',
      key: 'gross_profit',
    }
  ]
  const [showColumns, setShowColumns] = useState(columnDef.map((column) => column.key));
  const [isOpen, setIsOpen] = useState(false);
  const columnDropdownRef = createRef();
  const toggleDropdown = (e) => {
    if (columnDropdownRef.current.contains(e.target)) return;
    setIsOpen(!isOpen);
  };
  const handleColumnHideShow = (event, columnName) => {
    event.stopPropagation();
    if (showColumns.includes(columnName)) {
      setShowColumns(showColumns.filter((column) => column != columnName))
    } else {
      setShowColumns([...showColumns, columnName]);
    }
  }
  /****************************************/

  return (
    <>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Portfolio Analysis</h4>
          <div className="flex-shrink-0">
            <div className="d-flex">
              <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ms-3" style={{ borderRadius: '0.25rem', marginRight: '15px' }}>
                <ButtonGroup>
                  <Button color="info">Columns</Button>
                  <DropdownToggle tag="button" className="btn btn-info" split></DropdownToggle>
                </ButtonGroup>
                <DropdownMenu className="dropdown-menu-end" end={true}>
                  <div ref={columnDropdownRef}>
                    {columnDef.map((column) => (
                      <DropdownItem onClick={(event) => { handleColumnHideShow(event, column.key) }} key={column.key}>
                        <div className="form-check mb-1">
                          <Input className="form-check-input" type="checkbox" id={`column${column.key}`} checked={showColumns.includes(column.key)} value={column.key} />
                          <Label className="form-check-label" for="formCheck6">{column.name}</Label>
                        </div>
                      </DropdownItem>
                    ))}
                  </div>
                </DropdownMenu>
              </Dropdown>
              {/* <span className="p-2">Show</span>
              <select defaultValue="50" className="form-select" id="selling_item_limit" style={{ cursor: 'pointer' }} onChange={handleLimit}>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
              <span className="p-2">entries</span> */}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {sellingItemAjax && <Loader />}
          <div className={sellingItemAjax ? "opacity-0" : ""}>
            {sellingItemAjax && <Loader />}
            <div
              className={
                sellingItemAjax
                  ? "opacity-0 table-responsive table-card"
                  : "table-responsive table-card"
              }
              style={{ maxHeight: '500px', overflow: 'auto' }}
            >
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
                <thead className="text-muted table-light">
                  <tr style={{ position: 'sticky', top: '-2px', zIndex: 100 }}>
                    {columnDef.map((column) => (
                      // eslint-disable-next-line no-undef
                      // showColumns.includes(column.key) && <th className={column.className} key={column.key} scope="col">{column.performance_group} {column.sort && <i className="las la-arrow-down" style={{ cursor: 'pointer', transform: (sortColumn == column.key && sorttypefinalvalue == 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)') }} onClick={() => handleSort(column.key, 'sortcall')}></i>}</th>
                      showColumns.includes(column.key) && <th className={column.className} key={column.key} scope="col">{column.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(sellingItem || []).map((item, key) => (
                    <tr key={key}>
                      {showColumns.includes('performance_group') && (
                        <td
                          className="text-start"
                          onClick={() => handleItemName(item)}
                          style={{ cursor: 'pointer' }}
                        >
                          {item.performance_group !== "Total" ? (
                            <span
                              className={`badge bg-${item.performance_group === "Plowhorse"
                                ? "warning"
                                : item.performance_group === "Dog"
                                  ? "danger"
                                  : item.performance_group === "Cash Cow"
                                    ? "warning"
                                    : item.performance_group === "Puzzle"
                                      ? "light text-dark"
                                      : "success"
                                }`}
                              style={{
                                fontWeight: key === 0 ? 'bold' : 'normal' // Apply bold font style to the first row
                              }}
                            >
                              <b>{item.performance_group}</b>
                            </span>
                          ) : (
                            <b>{item.performance_group}</b>
                          )}

                          {item.performance_group !== "Total" ? (
                            <i
                              className="ri-external-link-line"
                              style={{
                                fontSize: '18px',
                                marginLeft: '10px',
                                position: 'relative',
                                top: '5px',
                                color: '#405189'
                              }}
                            ></i>
                          ) : (
                            ''
                          )}
                        </td>
                      )}
                      {showColumns.includes('item_sold') && (
                        <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                          {item.item_sold}
                        </td>
                      )}
                      {showColumns.includes('avg_check') && (
                        <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                          {currencyFormatWithAlert(item.avg_check)}
                        </td>
                      )}
                      {showColumns.includes('revenue') && (
                        <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                          {currencyFormatWithAlert(item.revenue)}
                        </td>
                      )}
                      {showColumns.includes('sales_mix') && (
                        <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                          {parseFloat(item.sales_mix).toFixed(2) + '%'}
                        </td>
                      )}
                      {showColumns.includes('revenue_mix') && (
                        <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                          {parseFloat(item.revenue_mix).toFixed(2) + '%'}
                        </td>
                      )}
                      {showColumns.includes('cost') && (
                        <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                          {currencyFormatWithAlert(item.cost)}
                        </td>
                      )}
                      {showColumns.includes('gross_profit') && (
                        <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                          <b>{currencyFormatWithAlert(item.gross_profit)}</b>
                        </td>
                      )}
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
            {/*<div className="mt-5 float-end">
              <nav>
                <CustomPagination
                  pageCount={pageCount}
                  setSelectedPage={setSelectedPage}
                />
              </nav>
            </div>*/}
          </div>
        </CardBody>
      </Card>

      {/* Modal for onclick Item Name */}
      <Modal className="modal-dialog modal-lg custom-bg" isOpen={modalItemName} toggle={() => { togItemName(); }} id="fullscreeexampleModal">
        <ModalHeader className="modal-title" id="exampleModalFullscreenLabel" toggle={() => { togItemName(); }}>
          {modalItem.performance_group}
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <Nav pills className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
                <NavItem>
                  <NavLink style={{ cursor: "pointer", background: "transparent" }} className={classnames({ active: justifyTab === "course", })} onClick={() => { justifyToggle("course"); }} >
                    Course
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{ cursor: "pointer" }} className={classnames({ active: justifyTab === "item", })} onClick={() => { justifyToggle("item"); }} >
                    Item
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={justifyTab} className="text-muted">

                <TabPane tabId="course" id="course">
                  <Card style={{ borderRadius: '0px 0px 8px 8px' }}>
                    <CardBody>
                      {
                        courseModalDetailAjax && <Loader />
                      }
                      {!courseModalDetailAjax && <>
                      <div
                        className={
                          courseModalDetailAjax
                            ? "opacity-0 table-responsive table-card"
                            : "table-responsive table-card"
                        }                        
                      >
                        <table className="table table-borderless table-centered align-middle table-nowrap mb-3 text-center">
                          <thead className="text-muted table-light">
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Item Sold</th>
                              <th scope="col">Avg Check</th>
                              <th scope="col">Revenue</th>
                              <th scope="col">Sales Mix</th>
                              <th scope="col">Revenue Mix</th>
                              <th scope="col">Cost</th>
                              <th scope="col">Item Gross Profit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(CategoryData || []).map((item, key) => (                            
                              <tr key={key}>
                                <td
                                  className="text-start"
                                  onClick={() => handleItemName(item)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {item.course_name !== "Total" ? (
                                    <span
                                      className={`badge bg-${item.course_name === "Starter"
                                              ? "warning"
                                              : item.course_name === "Drinks"
                                                ? "danger"
                                                : item.course_name === "Main"
                                                  ? "primary"
                                                  : item.course_name === "Desserts"
                                                    ? "success"
                                                    : item.course_name === "Others"
                                                      ? "info"
                                                      : "light text-dark"
                                              }`}
                                      style={{
                                        fontWeight: key === 0 ? 'bold' : 'normal' // Apply bold font style to the first row
                                      }}
                                    >
                                      <b>{item.course_name}</b>
                                    </span>
                                  ) : (
                                    <b>{item.course_name}</b>
                                  )}                                  
                                </td>
                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {item.item_sold}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {currencyFormatWithAlert(item.avg_check)}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {currencyFormatWithAlert(item.revenue)}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {parseFloat(item.sales_mix).toFixed(2) + '%'}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {parseFloat(item.revenue_mix).toFixed(2) + '%'}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {currencyFormatWithAlert(item.cost)}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  <b>{currencyFormatWithAlert(item.gross_profit)}</b>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      </>
                      }
                    </CardBody>
                  </Card>
                </TabPane>

                <TabPane tabId="item" id="item">
                  <Card style={{ borderRadius: '0px 0px 8px 8px' }}>
                    <CardBody>
                      {
                        courseModalDetailAjax && <Loader />
                      }
                      {!courseModalDetailAjax && <>
                      <div
                        className={
                          courseModalDetailAjax
                            ? "opacity-0 table-responsive table-card"
                            : "table-responsive table-card"
                        } 
                        style={{maxHeight: '500px', overflow: 'auto'}}                       
                      >
                        <table className="table table-borderless table-centered align-middle table-nowrap mb-3 text-center">
                          <thead className="text-muted table-light">
                            <tr style={{position: 'sticky',top: '-2px', zIndex: 100}}>
                              <th scope="col"></th>
                              <th scope="col">Item Sold</th>
                              <th scope="col">Avg Check</th>
                              <th scope="col">Revenue</th>
                              <th scope="col">Sales Mix</th>
                              <th scope="col">Revenue Mix</th>
                              <th scope="col">Cost</th>
                              <th scope="col">Item Gross Profit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(ItemsData || []).map((item, key) => (
                              <tr key={key}>
                                <td className="text-start" >
                                  <b>{item.name}</b>                                                                 
                                </td>
                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {item.item_sold}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {currencyFormatWithAlert(item.avg_check)}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {currencyFormatWithAlert(item.revenue)}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {parseFloat(item.sales_mix).toFixed(2) + '%'}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {parseFloat(item.revenue_mix).toFixed(2) + '%'}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  {currencyFormatWithAlert(item.cost)}
                                </td>

                                <td style={{ fontWeight: key === 0 ? 'bold' : 'normal' }}>
                                  <b>{currencyFormatWithAlert(item.gross_profit)}</b>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      </>
                      }
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>

            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

    </>
  );
};

export default PortfolioAnalysis;
