import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import logoLight from "../../assets/images/logo-light.png";
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

import ParticlesAuth from "./ParticlesAuth";

const Register = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            first_name: '',
            last_name:'',
            establishment:'',
            city: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            first_name: Yup.string().required("Please Enter Your First Name"),
            last_name: Yup.string().required("Please Enter Your Last Name"),
            city: Yup.string().required("Please Enter Your City"),
            establishment: Yup.string().required("Please Enter Your Establishment"),
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values));
        }
    });

    const { error, registrationError, success, isApiLoading,  AccountData} = useSelector(state => ({
        registrationError: state.Account.registrationError,
        success: state.Account.success,
        error: state.Account.error,
        isApiLoading:state.Account.loading,
        AccountData:state.Account.user
    }));
    
    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            document.title = "Registration Successful | "+process.env.REACT_APP_NAME;
        }
    }, [dispatch, success, error, history]);

    document.title = "SignUp | "+process.env.REACT_APP_NAME;
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-4 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                        <img src={logoLight} className="img-fluid" alt={process.env.REACT_APP_SMALL_NAME} width="180"/>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    {!success && 
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Create New Account</h5>
                                            <p className="text-muted">Get your free {process.env.REACT_APP_NAME} account now</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation" action="#">

                                                {error && registrationError ? (
                                                    <Alert color="danger"><div>
                                                        { registrationError.message } </div></Alert>
                                                ) : null}
                                                <div className="mb-3">
                                                    <Label htmlFor="first_name" className="form-label">First Name <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="first_name"
                                                        type="text"
                                                        placeholder="Enter first name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.first_name || ""}
                                                        invalid={
                                                            validation.touched.first_name && validation.errors.first_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.first_name && validation.errors.first_name ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.first_name}</div></FormFeedback>
                                                    ) : null}

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="last_name" className="form-label">Last Name <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="last_name"
                                                        type="text"
                                                        placeholder="Enter last name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.last_name || ""}
                                                        invalid={
                                                            validation.touched.last_name && validation.errors.last_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.last_name && validation.errors.last_name ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.last_name}</div></FormFeedback>
                                                    ) : null}

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email address"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="establishment" className="form-label">Establishment <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="establishment"
                                                        type="text"
                                                        placeholder="Enter establishment"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.establishment || ""}
                                                        invalid={
                                                            validation.touched.establishment && validation.errors.establishment ? true : false
                                                        }
                                                    />
                                                    {validation.touched.establishment && validation.errors.establishment ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.establishment}</div></FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="city" className="form-label">City <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="city"
                                                        type="text"
                                                        placeholder="Enter city"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.city || ""}
                                                        invalid={
                                                            validation.touched.city && validation.errors.city ? true : false
                                                        }
                                                    />
                                                    {validation.touched.city && validation.errors.city ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.city}</div></FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-4">
                                                    <p className="mb-0 fs-12 text-muted fst-italic">By registering you agree to the {process.env.REACT_APP_NAME}
                                                        <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium ms-1">Terms of Use</Link></p>
                                                </div>

                                                <div className="mt-4">
                                                    <button className="btn btn-success w-100" type="submit" disabled={isApiLoading}>Sign Up <Spinner className={isApiLoading ? "ms-1" : "ms-1 invisible"} size="sm"></Spinner></button>
                                                </div>

                                            </Form>
                                        </div>
                                    </CardBody> }
                                    {success && AccountData && 
                                    <CardBody className="p-4 text-center">
                                        <div className="avatar-lg mx-auto mt-2">
                                            <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                                <i className="ri-checkbox-circle-fill"></i>
                                            </div>
                                        </div>
                                        <div className="mt-4 pt-2">
                                            <h4 className="mb-5">Registration Successful !</h4>
                                            <p className="text-muted fs-14">Please check your email <code className="fs-14 text-secondary">{ AccountData.data.email }</code></p>
                                            {/* <p className="text-muted fs-14">You need to click the verification link in the email to proceed further</p> */}
                                        </div>
                                    </CardBody> }

                                </Card>
                                {!success && <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Signin </Link> </p>
                                </div>}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;
