import React from 'react';


const Placeholders = (props) => {
    return (
        <div className='position-relative h-100 w-100'>
            <div className='position-absolute h-100 w-100'>
                <p className="card-text placeholder-glow">
                    <span className="placeholder col-12"></span>
                    <span className="placeholder col-10"></span>
                    <span className="placeholder col-8"></span>
                    <span className="placeholder col-6"></span>
                </p> 
            </div>
        </div>
    );
};

export default Placeholders;