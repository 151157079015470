/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Loader from "../../Components/Common/Loader";
import { useEffect } from "react";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import CustomPagination from "../../Components/Common/CustomPagination";

const itemsPerPage = 10;

const RecommendationFeedback = ({
  showRecommendModal,
  setShowRecommendModal,
  parentDefaultCourse,
  parentMealPeriod,
}) => {
  const { filterData, filterAjax } = useSelector((state) => ({
    filterData: state.Dashboard.filterData,
    filterAjax: state.Dashboard.filterAjax,
  }));
  const [recommendItemsLoading, setRecommendItemsLoading] = useState(false);
  const [recommendItems, setRecommendItems] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCntRecommend, setPageCntRecommend] = useState(0);
  const [isSaveDisable, setIsSaveDisable] = useState(true);
  const [confirmShowModel, setConfirmShowModel] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [defaultCourse, setDefaultCourse] = useState(
    parentDefaultCourse ? parentDefaultCourse : 0
  );
  const [filterCourses, setFilterCourses] = useState([]);

  const [mealPeriod, setMealPeriod] = useState(
    parentMealPeriod ? parentMealPeriod : "All"
  );
  const [filterMealPeriod, setFilterMealPeriod] = useState([]);

  const submitRecommendApi = useCallback((saved) => {}, []);

  useEffect(() => {
    if (filterData.mealperiod) {
      setFilterMealPeriod(filterData.mealperiod);
    }

    if (filterData.courses) {
      setFilterCourses(filterData.courses);
      // filterData.courses.map((course) =>
      //   course.label === "Main" ? setDefaultCourse(parseInt(course.value)) : ""
      // );
    }
  }, [filterAjax, filterData]);

  useEffect(() => {
    if (isSaveDisable) {
      setRecommendItemsLoading(true);
      // postDataAPI({
      //   // dateFilter: leftFilterData,
      //   serviceName: POST_MENU_OPTIMIZATION.serviceName,
      //   op: POST_MENU_OPTIMIZATION.item_recommendation_op,
      //   mealPeriod: mealPeriod,
      //   course: defaultCourse,
      //   page: selectedPage,
      //   // recalculate: recalculate,
      // }).then((response) => {
      //   setRecommendItemsLoading(false);
      //   if (response.status === "ok") {
      //     // eslint-disable-next-line no-debugger
      //     // debugger;
      //     setRecommendItems(response.data.data);
      //     setPageCntRecommend(
      //       Math.ceil(response.data.totalData / itemsPerPage)
      //     );
      //   } else {
      //     console.log(response);
      //     // setPageCount(0);
      //   }
      // });

      handleSort(SortColumn, 'initial');
      
    } else setConfirmShowModel(true);
  }, [defaultCourse, mealPeriod, selectedPage, refresh]);

  const notifyCustom = (msg, type) =>
    toast(msg, {
      position: "top-right",
      hideProgressBar: true,
      className: `bg-${type} text-white`,
    });

  const changeMealPeriod = (value) => {
    setMealPeriod(value);
    setSelectedPage(0);
  };

  const changeDefaultCourse = (value) => {
    setDefaultCourse(value);
    setSelectedPage(0);
  };

  const onClickChkOption1 = (event, item) => {
    // eslint-disable-next-line no-debugger
    debugger;
    const id = event.target.value;
    const curDate = moment().format("DD/MM/YYYY");
    let optionJson = item.optionJson;
    let optionData = {
      date: curDate,
      status: event.target.checked ? "accepted" : "undecided",
      value: item.option1,
    };

    if (optionJson.length > 0) {
      if (optionJson[0].option1) optionJson[0].option1 = optionData;
      else optionJson = [{ ...optionJson[0], option1: optionData }];
    } else optionJson = [{ option1: optionData }];

    const updateData = recommendItems.map((item) =>
      item.item_id === id
        ? {
            ...item,
            optionJson: optionJson,
          }
        : item
    );

    setRecommendItems(updateData);
    setIsSaveDisable(false);
  };
  const onClickChkOption2 = (event, item) => {
    // eslint-disable-next-line no-debugger
    debugger;
    const id = event.target.value;
    const curDate = moment().format("DD/MM/YYYY");
    let optionJson = item.optionJson;
    let optionData = {
      date: curDate,
      status: event.target.checked ? "accepted" : "undecided",
      value: item.option2,
    };
    if (optionJson.length > 0) {
      if (optionJson[0].option2) optionJson[0].option2 = optionData;
      else optionJson = [{ ...optionJson[0], option2: optionData }];
    } else optionJson = [{ option2: optionData }];

    const updateData = recommendItems.map((item) =>
      item.item_id === id
        ? {
            ...item,
            optionJson: optionJson,
          }
        : item
    );

    setRecommendItems(updateData);
    setIsSaveDisable(false);
  };
  const onHandleSave = () => {
    // eslint-disable-next-line no-debugger
    // debugger;
    const filterItemsWithOptionJson = recommendItems.filter(
      (item) => item.optionJson.length > 0
    );

    const updateData = filterItemsWithOptionJson.map((item) => {
      return {
        item_id: item.item_id,
        optionJson: item.optionJson,
      };
    });

    postDataAPI({
      serviceName: POST_MENU_OPTIMIZATION.serviceName,
      op: POST_MENU_OPTIMIZATION.item_recommendation_accept_op,
      itemOptionArr: updateData,
      mealPeriod: mealPeriod,
      course: defaultCourse,
      accepted_date: moment().format("YYYY-MM-DD"),
    }).then((response) => {
      if (response.status === "ok") {
        notifyCustom(response.message, "success");
        setConfirmShowModel(false);
      } else {
        notifyCustom(response.message, "danger");
        console.log(response);
      }
      setIsSaveDisable(true);
      setRefresh(!refresh);
    });
  };
  const onHandleCancel = () => {
    postDataAPI({
      // dateFilter: leftFilterData,
      serviceName: POST_MENU_OPTIMIZATION.serviceName,
      op: POST_MENU_OPTIMIZATION.item_recommendation_op,
      mealPeriod: mealPeriod,
      course: defaultCourse,
      page: selectedPage,
      // recalculate: recalculate,
    }).then((response) => {
      setRecommendItemsLoading(false);
      if (response.status === "ok") {
        // eslint-disable-next-line no-debugger
        // debugger;
        setRecommendItems(response.data.data);
        setPageCntRecommend(Math.ceil(response.data.totalData / itemsPerPage));
      } else {
        console.log(response);
        // setPageCount(0);
      }
      setConfirmShowModel(false);
      setIsSaveDisable(true);
    });
  };

  /******** Sorting **** Starts*****/	
  const [SortColumn, setsortColumn] = useState('');

  const [isRotatedname, setIsRotatedname] = useState(false);
  const [isRotatedsold, setIsRotatedsold] = useState(false);
  const [isRotateditem_cost, setIsRotateditem_cost] = useState(false);
  
  const [SortType, setSortType] = useState('ASC');
  const handleSort = (value, callfrom) => {
    
    let sorttypefinalvalue;
    if(callfrom === 'initial'){      

      sorttypefinalvalue = localStorage.getItem('Reccomendation_SortType');

    }else{

      if(localStorage.getItem('Reccomendation_SortType') === ''){
          localStorage.setItem('Reccomendation_SortType', 'ASC');
          sorttypefinalvalue = 'ASC';                
      }else{
        if(localStorage.getItem('Reccomendation_SortType') === 'ASC'){   
          localStorage.setItem('Reccomendation_SortType', 'DESC');
          sorttypefinalvalue = 'DESC';
        }else{
          localStorage.setItem('Reccomendation_SortType', 'ASC');
          sorttypefinalvalue = 'ASC';
        }
      }      
          
    }
	
    setsortColumn(value);

    if(value === 'name'){
      setIsRotatedname(!isRotatedname);  
    }
    if(value === 'sold'){
      setIsRotatedsold(!isRotatedsold);  
    }
    if(value === 'item_cost'){
      setIsRotateditem_cost(!isRotateditem_cost);  
    }   		   
     
	  if (isSaveDisable) {
      setRecommendItemsLoading(true);
      postDataAPI({
        // dateFilter: leftFilterData,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.item_recommendation_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage,
        sort_column: value,
        sort_type: sorttypefinalvalue,
        // recalculate: recalculate,
      }).then((response) => {
        setRecommendItemsLoading(false);
        if (response.status === "ok") {
          // eslint-disable-next-line no-debugger
          // debugger;
          setRecommendItems(response.data.data);
          setPageCntRecommend(
            Math.ceil(response.data.totalData / itemsPerPage)
          );
        } else {
          console.log(response);
          // setPageCount(0);
        }
      });
    } else setConfirmShowModel(true);	  
    
  };
  /************END Sorting**************/

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <Modal
              size="xl"
              isOpen={showRecommendModal}
              toggle={() => {
                setShowRecommendModal(false);
              }}
              backdrop={"static"}
              id="staticBackdrop"
              centered
            >
              <ModalHeader
                toggle={() => {
                  setShowRecommendModal(false);
                }}
                className="mb-2"
              >
                Recommendation as of {moment().format("DD/MM/YYYY")}
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row md="2">
                    <Col className="mb-4">
                      <select
                        className="form-select"
                        value={mealPeriod}
                        id="mealPeriod"
                        onChange={(event) =>
                          changeMealPeriod(event.target.value)
                        }
                      >
                        <option value="0">All Meal Periods</option>
                        {!filterAjax &&
                          filterMealPeriod.length > 0 &&
                          filterMealPeriod.map((item) => {
                            return (
                              <option key={item.sp_id} value={item.sp_id}>
                                {item.title}
                              </option>
                            );
                          })}
                      </select>
                    </Col>
                    <Col className="mb-4">
                      <select
                        className="form-select"
                        value={defaultCourse}
                        id="course"
                        onChange={(event) =>
                          changeDefaultCourse(parseInt(event.target.value))
                        }
                      >
                        <option value="0">All Courses</option>
                        {!filterAjax &&
                          filterCourses.length > 0 &&
                          filterCourses.map((item) => {
                            return (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                      </select>
                    </Col>
                  </Row>
                  <Row md="1">
                    <Col className="mb-4">
                      {recommendItemsLoading && <Loader />}
                      <div
                        className={
                          recommendItemsLoading
                            ? "opacity-0 table-responsive table-card"
                            : "table-responsive table-card"
                        }
                      >
                        <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
                          <thead className="text-muted table-light">
                            <tr>
                              <th scope="col">Menu Item
                                <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedname ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('name','sortcall')}></i>
                              </th>
                              <th scope="col">Sold
                                <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedsold ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('sold','sortcall')}></i>
                              </th>
                              <th scope="col">Item Cost
                                <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotateditem_cost ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('item_cost','sortcall')}></i>
                              </th>
                              <th scope="col">Item Category</th>
                              <th scope="col">Option 1</th>
                              <th scope="col">Option 2</th>
                              {/* <th scope="col">Accepted Date</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {(recommendItems || []).map((item, key) => (
                              <tr key={key}>
                                <td>{item.name}</td>
                                <td>{item.sold}</td>
                                <td>{item.item_cost}</td>
                                  <td>
                                    <span className={`badge bg-${
                              item.performance_group === "Plowhorse"
                                ? "warning"
                                : item.performance_group === "Dog"
                                ? "danger"
                                : item.performance_group === "Cash Cow"
                                ? "warning"
                                : item.performance_group === "Puzzle"
                                ? "light text-dark"
                                : "success"
                            }
                            `}>
                            {item.performance_group}
                          </span>
                          </td>
                                <td>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`option1-${item.item_id}`}
                                      value={item.item_id}
                                      onChange={(e) =>
                                        onClickChkOption1(e, item)
                                      }
                                      checked={
                                        item.optionJson &&
                                        item.optionJson.length > 0 &&
                                        item.optionJson[0].option1 &&
                                        item.optionJson[0].option1.status ===
                                          "accepted"
                                      }
                                    />
                                    <Label
                                      className="form-check-label ps-1"
                                      for={`option1-${item.item_id}`}
                                    >
                                      {item.option1}
                                    </Label>
                                  </div>
                                  {item.optionJson &&
                                    item.optionJson.length > 0 &&
                                    item.optionJson[0].option1 &&
                                    item.optionJson[0].option1.status ===
                                      "accepted" && (
                                      <div className="text-start">
                                        <Label>
                                          {moment(
                                            item.optionJson[0].option1.date
                                          , 'DD/MM/YYYY').format("DD MMM YYYY")}
                                        </Label>
                                      </div>
                                    )}
                                </td>
                                <td>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`option2-${item.item_id}`}
                                      value={item.item_id}
                                      onChange={(e) =>
                                        onClickChkOption2(e, item)
                                      }
                                      checked={
                                        item.optionJson &&
                                        item.optionJson.length > 0 &&
                                        item.optionJson[0].option2 &&
                                        item.optionJson[0].option2.status ===
                                          "accepted"
                                      }
                                    />
                                    <Label
                                      className="form-check-label ps-1"
                                      for={`option2-${item.item_id}`}
                                    >
                                      {" "}
                                      {item.option2}
                                    </Label>
                                  </div>
                                  {item.optionJson &&
                                    item.optionJson.length > 0 &&
                                    item.optionJson[0].option2 &&
                                    item.optionJson[0].option2.status ===
                                      "accepted" && (
                                      <div className="text-start">
                                        <Label>
                                          {moment(
                                            item.optionJson[0].option2.date
                                          ,'DD/MM/YYYY').format("DD MMM YYYY")}
                                        </Label>
                                      </div>
                                    )}
                                </td>
                                {/* <td>
                                  {(chkOption1.includes(item.item_id) ||
                                    chkOption2.includes(item.item_id)) &&
                                    moment().format("DD MMMM YYYY")}
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="mt-4 float-end">
                        <nav>
                          <CustomPagination
                            pageCount={pageCntRecommend}
                            setSelectedPage={setSelectedPage}
                          />
                        </nav>
                      </div>
                    </Col>
                    <Col className="text-end">
                      <Button
                        color="success"
                        onClick={() => {
                          onHandleSave();
                        }}
                        disabled={isSaveDisable}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>

            <Modal
              // size="xl"
              isOpen={confirmShowModel}
              toggle={() => {
                setConfirmShowModel(false);
              }}
              backdrop={"static"}
              id="staticBackdrop"
              centered
            >
              <ModalHeader
                toggle={() => {
                  setConfirmShowModel(false);
                }}
                className="mb-2"
              >
                Confirmation
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row md="1">
                    <Col className="mb-4">
                      <p>Would u like to save modified options?</p>
                    </Col>
                    <Col className="text-end">
                      <Button
                        color="success"
                        className="me-3"
                        onClick={() => onHandleSave()}
                        disabled={isSaveDisable}
                      >
                        Save
                      </Button>
                      <Button color="light" onClick={() => onHandleCancel()}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </React.Fragment>
  );
};

export default RecommendationFeedback;
