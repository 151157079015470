import {
  VERIFY_CODE,
  VERIFY_CODE_SUCCESSFUL,
  VERIFY_CODE_FAILED
} from "./actionTypes"

export const verifyCode = reg_ver_code => {
  return {
    type: VERIFY_CODE,
    payload: { reg_ver_code },
  }
}

export const verifyCodeSuccessful = user_email => {
  return {
    type: VERIFY_CODE_SUCCESSFUL,
    payload: user_email,
  }
}

export const verifyCodeFailed = (data) => {
  return {
    type: VERIFY_CODE_FAILED,
    payload: data,
  }
}