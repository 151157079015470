import {
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESSFUL,
  VERIFY_EMAIL_FAILED
} from "./actionTypes"

export const verifyEmail = reg_ver_code => {
  return {
    type: VERIFY_EMAIL,
    payload: { reg_ver_code },
  }
}

export const verifyEmailSuccessful = user_email => {
  return {
    type: VERIFY_EMAIL_SUCCESSFUL,
    payload: user_email,
  }
}

export const verifyEmailFailed = (data) => {
  return {
    type: VERIFY_EMAIL_FAILED,
    payload: data,
  }
}